@import "../../../styles/utils.scss";


.wrapper {
	width: 100%;
	background-color: #181818;
	position: relative;
}

.container {
	width: 1080px;
	margin: 0px auto;
	padding-top: 84px;
	padding-bottom: 68px;
}

.title {
	@include Pretendard(42px, 800, 0.95);
	letter-spacing: -0.79px;
	color: #f6f6f8;
	margin-bottom: 50px;
}

.cards {
	display: flex;
	gap: 28px 50px;
	flex-basis: 100%;
	flex-wrap: wrap;
}

.slider {
	width: calc(100% + (100vw - 1080px) / 2);
}

.stoped {
	display: flex;
	gap: 0px 25px;
}