@import "../../../../styles/utils.scss";


.wrapper {
	width: 100%;
	background-color: #181818;
}

.container {
	width: calc(100% - 24px);
	margin: 0px auto;
	padding: 12px;
	padding-top: 40px;
	padding-bottom: 10px;
}

.title {
	@include Pretendard(26px, 800, 40px);
	letter-spacing: -0.488px;
	color: #f6f6f8;
	margin-bottom: 0px;
}