@import "../../styles/utils.scss";

.tabMenu {
	width: 1080px;
	margin: 0px auto;
	display: flex;
}

.menuElemActive {
	padding: 5px 3px;
	@include Pretendard(20px, 600, normal);
	letter-spacing: -0.29px;
	color: #151516;
	border-bottom: 4px #ff424d solid;
	width: fit-content;
	margin-right: 39px;
	cursor: pointer;
}

.menuElem {
	padding: 5px 3px;
	@include Pretendard(20px, 600, normal);
	letter-spacing: -0.29px;
	color: #151516;
	width: fit-content;
	margin-right: 39px;
	cursor: pointer;
}

.line {
	width: 100%;
	height: 1px;
	background-color: #151516;
}

.leftSide {
	width: 709px;
	margin-right: 32px;
}

.contents {
	display: flex;
	width: 1080px;
	margin: 0px auto;
	position:relative;
	min-height: 700px;
}

.topBtn {
	position: fixed;
	right: 40px;
	bottom: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 2px;
	z-index: 5;
	box-shadow: 0 4px 0 0 #000;
	border: solid 1px #151516;
	background-color: #fff;
}