@import "../../styles/utils.scss";

.backgroundBox {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 350px;
  margin-top: 80px;
  background-color: #18191a;
}

.topMini {
  @include SpoqaHanSansNeo(16px, normal, 1);
  letter-spacing: -0.27px;
  color: #fbfbfb;
  @include desktop {
    margin-top: 40px;
  }
}

.ntfTitle {
  @include SpoqaHanSansNeo(36px, normal, 1.44);
  letter-spacing: -0.6px;
  color: #fbfbfb;
  word-break: keep-all;
  @include desktop {
    width:447px;
    margin-top: 8px;
  }
}

.btns {
  display: flex;
  margin-top: 64px;
}

.timerWrapper {
  margin-top: 51px;
}

.mintBtn {
  @include SpoqaHanSansNeo(16px, 500, normal);
  width: 139px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #ff424d;
  color: #fff;
}

.mintBtn img {
  width: 18px;
  height: 20px;
  margin-left: 8px;
}

.shoutBtn {
  @include SpoqaHanSansNeo(16px, 500, normal);
  width: 155px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #fff;
  background-color: #18191a;
  color: #fff;
  margin-left: 28px;
}

.shoutBtn img {
  width: 20px;
  height: 16px;
  margin-left: 12px;
}

.contentWrap {
  @include desktop {
    max-width: 960px;
    width: 100%;
    margin: auto;

    padding-top: 124px;
    display: flex;
  }
}

.imgBox {
  @include mobile {
    background-color: #18191a;

    padding: 0 20px 32px;

    img {
      width: 100%;
    }
  }
  @include desktop {
    img {
      width: 420px;
      height: 420px;
      margin-right: 67px;
    }
  }
}

.direction {
  display: flex;
  flex-direction: column;
}

.dateBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:first-child {
      margin-right: 8px;
    }
  }

  @include mobile {
    @include SpoqaHanSansNeo(14px, 700, 22px);
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 16px 20px 16px;
    background-color: #18191a;
  }

  @include desktop {
    @include SpoqaHanSansNeo(16px, 700, 24px);
    letter-spacing: -0.5px;
    color: #ffffff;
    margin-bottom: 20px;
  }
}

.title {
  @include mobile {
    @include SpoqaHanSansNeo(24px, 300, 30px);
    letter-spacing: -0.3px;
    color: white;
    background-color: #18191a;
    padding: 0 20px 4px;
  }
  @include desktop {
    @include SpoqaHanSansNeo(32px, 300, 36px);
    letter-spacing: -1px;
    color: white;
    margin-bottom: 8px;
  }
}

.artistName {
  @include mobile {
    @include SpoqaHanSansNeo(14px, 700, 22px);
    letter-spacing: -0.2px;
    color: white;
    background-color: #18191a;
    padding: 0 20px 24px;
  }
  @include desktop {
    @include SpoqaHanSansNeo(16px, 700, 24px);
    letter-spacing: -0.5px;
    color: white;
    margin-bottom: 47px;
  }
}

.description {
  @include mobile {
    @include SpoqaHanSansNeo(14px, 500, 22px);
    letter-spacing: -0.2px;
    color: white;
    background-color: #18191a;
    padding: 0 20px 32px;
  }

  @include desktop {
    @include SpoqaHanSansNeo(16px, 500, 24px);
    letter-spacing: -0.3px;
    color: white;
    margin-bottom: 90px;
  }
}

.times {
  display: flex;
  text-align: center;
  @include mobile {
    margin: 34px 20px 46px;
  }

  .timeBox {
    width: 100%;
    display: inline-block;
    border-radius: 14px;
    background-color: #f2f3f5;

    padding: 8px;

    @include mobile {
      margin-right: 12px;
    }
    @include desktop {
      margin-right: 16px;
    }
    &:last-child {
      margin-right: 0;
    }

    :first-child {
      color: #1b1c23;
      @include mobile {
        @include Montserrat(32px, 600, 42px);
      }

      @include desktop {
        @include SpoqaHanSansNeo(42px, 600, 42px);
        padding: 20px 0;
      }
    }

    :last-child {
      color: #babbc0;
      @include mobile {
        @include SpoqaHanSansNeo(12px, 500, 16px);
        letter-spacing: -0.2px;
      }
      @include desktop {
        @include SpoqaHanSansNeo(14px, 500, 30px);
        letter-spacing: -0.23px;
      }
    }
  }
}

.imageBox {
  @include mobile {
    width: 100%;

    img {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  @include desktop {
    width: 420px;
    margin-right: 47px;

    img {
      width: 420px;
      height: 420px;
    }
  }
}

.container {
  @include desktop {
    max-width: 960px;
    width: 100%;
    margin: auto;
  }
}

.graphArea {
  @include mobile {
    margin: 0 20px;
    background-color: #f6f6f8;
    border-radius: 4px;
    padding: 16px 0;
    margin-bottom: 80px;
  }

  @include desktop {
    width: 100%;
    display: flex;
    padding-left: 18px;
    align-items: center;
    border-radius: 4px;
    height: 64px;
    background-color: #f6f6f8;

    margin-bottom: 96px;
  }
}

.collectBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 99px;
}

.contentBox {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;

  @include desktop {
    margin-right: 22px;
  }

  .AlbumImage {
    @include mobile {
      width: 138px;
      height: 138px;
      padding: 8px;
    }

    @include desktop {
      width: 200px;
      height: 200px;

      margin: 12px 12px 0;
    }
  }

  .CircleImage {
    @include mobile {
      position: relative;
      top: -36px;
      left: 10px;
      width: 24px;
      height: 24px;
    }
    @include desktop {
      position: relative;
      top: -28px;
      left: 24px;
      width: 56px;
      height: 56px;
    }
  }

  .info {
    .artist {
      @include mobile {
        @include SpoqaHanSansNeo(12px, 500, 16px);
        letter-spacing: -0.2px;
        color: #202429;
        margin-left: 4px;
        margin-bottom: 8px;
        padding: 0 8px;
      }

      @include desktop {
        @include SpoqaHanSansNeo(12px, 500, 16px);
        letter-spacing: -0.2px;
        color: #202429;
        margin: -16px 16px 8px;
      }
    }

    .albumName {
      color: #202429;

      @include mobile {
        @include SpoqaHanSansNeo(14px, 600, 16px);
        letter-spacing: -0.2px;
        padding: 0 8px;
      }

      @include desktop {
        margin: 0 16px;
        padding-bottom: 8px;

        @include SpoqaHanSansNeo(16px, 600, 16px);
        letter-spacing: -0.3px;
      }
    }

    .albumNumber {
      padding: 0 8px;
      color: #202429;

      @include mobile {
        @include SpoqaHanSansNeo(14px, 600, 16px);
        letter-spacing: -0.2px;
        margin: 4px 0 12px;
      }

      @include desktop {
        margin: 0 16px 8px;

        span {
          @include SpoqaHanSansNeo(16px, 600, 16px);
          letter-spacing: -0.3px;
        }
        font-size: 10px;
        letter-spacing: -0.19px;
      }
    }

    .priceInfo {
      @include mobile {
        align-items: center;
        margin: 0 8px 24px;

        @include SpoqaHanSansNeo(14px, 600, 16px);
        letter-spacing: -0.2px;
        color: #202429;

        span {
          color: #202429;

          &:first-child {
            @include SpoqaHanSansNeo(12px, 500, 16px);
            letter-spacing: -0.2px;
            margin-bottom: 2px;
          }

          &:last-child {
            @include SpoqaHanSansNeo(10px, 600, 16px);
            letter-spacing: -0.2px;
          }
        }
      }

      @include desktop {
        margin-left: 12px;
        margin-bottom: 23px;
        color: #202429;

        span {
          @include SpoqaHanSansNeo(12px, 500, 16px);
          letter-spacing: -0.2px;
        }

        @include SpoqaHanSansNeo(16px, 600, 16px);
        letter-spacing: -0.3px;
      }

      img {
        @include mobile {
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }

        @include desktop {
          margin: 0 16px;

          width: 32px;
          height: 32px;
        }
      }
    }
  }

  hr {
    width: 100%;
    border: none;
    border-top: 1px solid #f6f6f8;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e2e2ec;
}

.detailTitle {
  @include mobile {
    @include SpoqaHanSansNeo(18px, 700, 18px);
    letter-spacing: -0.3px;
    color: #151516;
    margin: 40px 20px 32px;
  }
  @include desktop {
    @include SpoqaHanSansNeo(24px, 700, 24px);
    letter-spacing: -0.4px;
    color: #151516;

    padding-top: 40px;
    margin-bottom: 32px;
  }
}

.subTitle {
  @include mobile {
    @include SpoqaHanSansNeo(16px, 700, 16px);
    letter-spacing: -0.3px;
    color: #151516;
    margin: 0 20px 16px;
  }

  @include desktop {
    @include SpoqaHanSansNeo(20px, 700, 20px);
    letter-spacing: -0.3px;
    color: #151516;

    margin-bottom: 16px;
  }
}

.detailDescription {
  @include mobile {
    @include SpoqaHanSansNeo(14px, 500, 22px);
    letter-spacing: -0.2px;
    color: #43434a;
    margin: 0 20px 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  @include desktop {
    @include SpoqaHanSansNeo(16px, 500, 22px);
    letter-spacing: -0.3px;
    color: #43434a;

    margin-bottom: 40px;
  }
}

.descriptionBox {
  @include mobile {
    @include SpoqaHanSansNeo(10px, 500, 16px);
    letter-spacing: -0.2px;
    color: #202429;
    padding: 0 8px;
    display: flex;
    margin-bottom: 6px;

    :first-child {
      margin-right: 4px;
    }
  }

  @include desktop {
    margin: 0 12px;
    display: flex;
    @include SpoqaHanSansNeo(10px, 500, 17px);
    color: #202429;
    letter-spacing: -0.19px;
    padding-bottom: 10px;

    :first-child {
      margin-right: 4px;
    }
  }
}

.checkContents {
  @include mobile {
    @include SpoqaHanSansNeo(10px, 500, 16px);
    letter-spacing: -0.2p;
    color: #202429;
    padding: 0 8px;
    display: flex;

    img {
      width: 12px;
      height: 12px;
    }

    :first-child {
      margin-right: 2px;
    }
  }

  @include desktop {
    margin: 0 12px;
    display: flex;
    @include SpoqaHanSansNeo(10px, 500, 17px);
    color: #202429;
    letter-spacing: -0.19px;
    padding-bottom: 6px;

    img {
      width: 13px;
      height: 13px;
    }

    :first-child {
      margin-right: 2px;
    }
  }
}

.readMore {
  @include mobile {
    @include SpoqaHanSansNeo(10px, 600, 17px);
    letter-spacing: -0.19px;
    color: #ff424d;
    margin: 12px 0 24px;
    text-align: center;
  }

  @include desktop {
    @include SpoqaHanSansNeo(10px, 600, 17px);
    letter-spacing: -0.19px;
    color: #ff424d;
    text-align: center;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 36px;
  }
}

.collectBtn {
  @include mobile {
    @include SpoqaHanSansNeo(12px, 500, 16px);
    letter-spacing: -0.2px;
    color: #000000;
    margin: 0 8px 8px;
    text-align: center;
    padding: 8px 0;
    border-radius: 6px;
    border: solid 0.5px #000;
    background-color: #fff;
  }

  @include desktop {
    cursor: pointer;
    margin: 0 12px 12px;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0;
    border: solid 0.5px #000;
    background-color: #fff;

    @include SpoqaHanSansNeo(12px, 500, 16px);
    letter-spacing: -0.2px;
  }
}

.openSeaBtn {
  @include mobile {
    @include SpoqaHanSansNeo(12px, 500, 16px);
    letter-spacing: -0.2px;
    color: #ffffff;
    background-color: #13141a;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    margin: 0 8px 8px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  @include desktop {
    cursor: pointer;
    border-radius: 5px;
    margin: 0 12px 12px;

    background-color: #13141a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;

    @include SpoqaHanSansNeo(12px, 50, 16px);
    letter-spacing: -0.2px;
    color: #ffffff;

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
}

.token {
  @include mobile {
    @include SpoqaHanSansNeo(14px, 600, 14px);
    letter-spacing: -0.2px;
    color: #acacb2;
    text-align: center;
    margin: 16px 0 32px;
  }

  @include desktop {
    text-align: center;
    margin-top: 18px;

    @include SpoqaHanSansNeo(14px, 600, 14px);
    letter-spacing: -0.2px;
    color: #acacb2;
  }
}

.tableContent {
  display: flex;
  border-radius: 12px;
  border: solid 1px #e2e2ec;
  @include mobile {
    margin: 0 20px 40px;
    padding: 12px;
  }

  @include desktop {
    align-items: center;
    padding: 32px 32px 32px 0;
    margin-bottom: 40px;
  }
}

.tableBox {
  @include mobile {
    :last-child {
      @include SpoqaHanSansNeo(10px, 600, 14px);
      letter-spacing: -0.2px;
      color: #babbc0;
      margin-top: 8px;
    }
    flex: 1;
    padding: 0 12px;

    border-left: 1px solid #e2e2ec;

    &:first-child {
      border: none;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include desktop {
    padding-left: 32px;
    flex: 1;
    border-left: 1px solid #e2e2ec;
    :last-child {
      @include SpoqaHanSansNeo(14px, 600, 14px);
      letter-spacing: -0.2px;
      color: #babbc0;
    }
  }
}

.tableTitle {
  @include mobile {
    @include SpoqaHanSansNeo(10px, 600, 14px);
    letter-spacing: -0.2px;
    color: #63626b;
  }

  @include desktop {
    @include SpoqaHanSansNeo(14px, 600, 14px);
    letter-spacing: -0.2px;
    color: #63626b;
    margin-bottom: 16px;
  }
}

.tableBoldText {
  @include mobile {
    @include SpoqaHanSansNeo(18px, 700, normal);
    color: #151516;
    margin-top: 16px;
  }

  @include desktop {
    @include SpoqaHanSansNeo(28px, 700, 28px);
    color: #151516;
    margin-bottom: 12px;
  }
}

.aboutBox {
  @include mobile {
    @include SpoqaHanSansNeo(14px, 500, 22px);
    letter-spacing: -0.2px;
    color: #151516;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 18px;

    &:last-child {
      margin: 0 20px 40px;
    }
  }

  @include desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include SpoqaHanSansNeo(16px, 500, 24px);
    letter-spacing: -0.2px;
    color: #151516;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 39.5px;
    }
  }
}

.collectorContainer {
  @include mobile {
    margin-bottom: 100px;
  }

  @include desktop {
    margin-bottom: 200px;
  }
}

.collectorWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @include mobile {
    margin: 0 20px 32px;
  }
}

.leftContent {
  display: flex;

  img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
}

.leftWrap {
  div:first-child {
    @include SpoqaHanSansNeo(16px, 600, 18px);
    letter-spacing: -0.3px;
    color: #202429;
    margin-bottom: 7px;
  }

  div:last-child {
    @include SpoqaHanSansNeo(12px, 500, 16px);
    letter-spacing: -0.2px;
    color: #63626b;
  }
}

.rightContent {
  @include SpoqaHanSansNeo(14px, 500, 14px);
  letter-spacing: -0.2px;
  div:first-child {
    color: #babbc0;
    margin-bottom: 7px;
  }

  div:last-child {
    color: #151516;
  }
}

.modalTitle {
  @include SpoqaHanSansNeo(14px, 700, 22px);
  letter-spacing: -0.2px;
  color: #202429;
}

.modalContainer {
  margin-top: 32px;

  img {
    width: 44px;
    height: 44px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.firstContainer {
  img {
    margin-right: 30px;
    margin-bottom: 28px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.secondContainer {
  img {
    margin-right: 30px;
  }
}
