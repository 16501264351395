@import "../../../styles/utils.scss";

.wrapper {
	width: 100%;
	height: 303px;
	margin-top: 80px;
	background: linear-gradient(to bottom, #181818 0%,#181818 54.3%, white 54.3%, white 100% );
}

.container {
	width: 1080px;
	margin: 0px auto;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 303px;
}

.profileImg {
	width: 152px;
	height: 152px;
	object-fit: cover;
	border-radius: 4px;
	border: solid 2px #181818;
	margin-bottom: 74px;
}

.blackBtn {
	padding: 14px;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.2px;
	color: #fff;
	display: flex;
	align-items: center;
	border-radius: 2px;
	background-color: #151516;
	margin-bottom: 74px;
	margin-left: 24px;
	cursor: pointer;
}

.blackBtn img {
	margin-left: 8px;
	width: 20px;
	height: 20px;
	object-fit: contain;
}

.whiteBtn {
	padding: 12px 26px;
	@include Pretendard(14px, 500, 1.14);
	letter-spacing: -0.26px;
	color: #181818;
	cursor: pointer;
	display: flex;
	align-items: center;
	border: solid 1px #181818;
	margin-left: 24px;
	margin-bottom: 74px;
	border-radius: 2px;
}

.whiteBtn img {
	margin-right: 8px;
	width: 22px;
	height: 22px;
	object-fit: contain;
}

.left {
	display: flex;
	align-items: flex-end;
	height: 303px;
}

.right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.shadowBtn {
	width: fit-content;
	border-radius: 2px;
	box-shadow: 2px 2px 0 0 #000;
	border: solid 1px #181818;
	background-color: #fff;
	padding: 16px 25px 14px;
	@include Pretendard(14px, 500, 1.14);
	letter-spacing: -0.26px;
	color: #181818;
	margin-bottom: 74px;
	cursor: pointer;
}

.email {
	margin-bottom: 166px;
	height: 16px;
	text-align: right;
	@include Pretendard(12px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #fff;
	opacity: 0.5;
}

.nickname {
	margin-bottom: 9px;
	@include Pretendard(24px, 700, normal);
	letter-spacing: -0.75px;
	text-align: right;
	color: #fff;

}