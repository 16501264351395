@import "../../../styles/utils.scss";

.container {
	width: 708px;
	margin-bottom: 63px;
}

.titleRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 28px;
}

.title {
	@include Pretendard(20px, 700, 1);
	letter-spacing: -0.3px;
	color: #151516;
}

.titleRight {
	@include Pretendard(18px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #babbc0;
}

.row {
	margin-bottom: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rowImg {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	object-fit: contain;
	margin-right: 16px;
}

.left {
	display: flex;
	align-items: center;
}

.nickname {
	@include Pretendard(16px, 500, 1.13);
	letter-spacing: -0.3px;
	color: #202429;
	margin-bottom: 7px;
}
.addr {
	@include Pretendard(12px, 400, 1.33);
	letter-spacing: -0.2px;
	color: #63626b;
}

.tierName {
	margin-top: 7px;
	margin-bottom: 9px;
	@include Pretendard(16px, 400, 0.88);
	letter-spacing: -0.23px;
	color: #babbc0;
	text-align: right;
}

.ID {
	@include Pretendard(16px, 400, 0.88);
	letter-spacing: -0.23px;
	color: #151516;
	text-align: right;
}

.viewBtn {
	cursor: pointer;
	@include Pretendard(17px, 400, normal);
	padding: 10px 25px;
	width: fit-content;
	margin: 0px auto;
	color: #151516;
	margin-top: 44px;
	border-radius: 4px;
	box-shadow: 4px 4px 0 0 #000;
	border: solid 1px #151516;
	background-color: #fff;
}