@import "../../../styles/utils.scss";

.container {
	width: 708px;
	padding-bottom: 12px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 69.5px;
}

.title {
	@include Pretendard(24px, 700, normal);
	letter-spacing: -0.36px;
	color: #151516;
	margin-bottom: 28px;
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 28px;
}

.left {
	@include Pretendard(18px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #151516;
}

.right {
	@include Pretendard(18px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #151516;
}

.rightLink {
	@include Pretendard(18px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #404eed;
	cursor: pointer;
}