@import "../../../../styles/utils.scss";

.wrapper {
	width: 100%;
	background-color: #000;
	padding-top: 24px;
	padding-bottom: 16px;
}

.btnWrapper {
	display: flex;
	margin: 24px 98px;
	margin-top: 0px;
	width: calc(100% - 196px);
	gap: 32px;
}

.btnWrapper img {
	width: 24px;
	height: 24px;
	object-fit: contain;
	cursor: pointer;
	filter: invert(100%);
}

.copyright {
	color: #FFF;
	text-align: center;
	@include Pretendard(12px, 500, 20px);
	letter-spacing: -0.225px;
	margin-bottom: 24px;
}

.logow {
	display: block;
	width: 54px;
	height: 10px;
	object-fit: contain;
	margin: 0px auto;
}
