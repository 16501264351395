@import "../../styles/utils.scss";

.container {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(21, 21, 22, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrapper {
	padding: 30px 25px;
	width: 523px;
	background-color: white;
	border: 1px solid #181818;
	position: relative;
}

.x {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
}

.title {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
	text-align: center;
	margin-bottom: 39px;
}

.blackBtn {
    @include Pretendard(20px, 500, 1.09);
    margin-top: 28px;
    width: 100%;
    height: 65px;
    border-radius: 32.5px;
    background-color: #000;
    letter-spacing: -0.29px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.blackBtn img {
    position: absolute;
    width: 37px;
    height: 37px;
    object-fit: contain;
    left: 20px;
}

.whiteBtn {
    @include Pretendard(20px, 500, 1.09);
    margin-top: 28px;
    width: 100%;
    height: 65px;
    border-radius: 32.5px;
    background-color: #fff;
    letter-spacing: -0.29px;
    text-align: center;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: solid 2px #000;
    cursor: pointer;
}

.whiteBtn img {
    position: absolute;
    width: 37px;
    height: 37px;
    object-fit: contain;
    left: 20px;
}