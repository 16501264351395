@import "../../styles/utils.scss";

.wideContainer {
	@include desktop {
		width: 360px;
		border-radius: 5px;
		box-shadow: 0 2px 20px 0 rgba(34, 11, 11, 0.1);
		background-color: #fff;
		overflow: hidden;
	}
	@include mobile {
		width: 220px;
		border-radius: 5px;
		box-shadow: 0 2px 20px 0 rgba(34, 11, 11, 0.1);
		background-color: #fff;
		overflow: hidden;
	}
}

.imgContainer {
	@include desktop {
		width: 360px;
		height: 180px;
	}
	@include mobile {
		width: 220px;
		height: 90px;
	}
}

.imgContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.remainContainer {
	background-color: #13141a;
	color: #fbfbfb;
	@include desktop {
		@include SpoqaHanSansNeo(12px, normal, 1.33);
		letter-spacing: -0.2px;
		padding: 8px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(10px, normal, 1.6);
		letter-spacing: -0.17px;
		padding: 3px 6px;
	}
}

.textContainer {
	@include desktop {
		padding: 20px 8px 14px 8px;
	}
	@include mobile {
		padding: 11px 6px 8px 6px;
	}
}

.artist {
	color: #202429;
	@include desktop {
		@include SpoqaHanSansNeo(12px, normal, 1.33);
		letter-spacing: -0.2px;
		margin-bottom: 4px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(8px, normal, 2);
		letter-spacing: -0.13px;
		margin-bottom: 2px;
	}
}

.details {
	@include desktop {
		@include SpoqaHanSansNeo(16px, 500, 1.5);
		letter-spacing: -0.3px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(10px, 500, 1.6);
		letter-spacing: -0.19px;
	}
	color: #202429;
}

.squareContainer {
	@include desktop {
		width: 192px;
		height: 192px;
		border-radius: 10px;
		margin-right: 20px;
	}
	@include mobile {
		width: 92px;
		height: 92px;
		border-radius: 6px;
		margin-right: 14px;
	}
	overflow: hidden;
	background-size: cover;
	position: relative;
}

.ticker {
	@include desktop {
		width: 22px;
		height: 22px;
		margin: 8px;
	}
	@include mobile {
		width: 12px;
		height: 12px;
		margin: 5px;
	}
	border-radius: 100px;
	position: absolute;
	z-index: 2;
}

.ticker img {
	width: 100%;
	height: 100%;
}

.albumInfo {
	@include desktop {
		width: calc(100% - 16px);
		height: calc(100% - 16px);
		padding: 8px;
	}
	@include mobile {
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		padding: 5px;
	}
	background-color: rgba(0, 0, 0, 0.45);
	opacity: 0;
	overflow: hidden;
	position: relative;
	transition: all 0.3s
}

.squareContainer:hover .albumInfo {
	opacity: 1;
}

.title {
	position: absolute;
	@include desktop {
		width: calc(100% - 16px);
		bottom: 42px;
		@include SpoqaHanSansNeo(16px, bold, 1.31);
		letter-spacing: -0.27px;
		margin-top: 90px;
	}
	@include mobile {
		width: calc(100% - 10px);
		bottom: 23px;
		@include SpoqaHanSansNeo(9px, bold, 1.56);
		letter-spacing: -0.15px;
	}
	color: #fff;
	word-break: keep-all;
}

.under {
	position: absolute;
	@include desktop {
		width: calc(100% - 16px);
		bottom: 8px;
	}
	@include mobile {
		width: calc(100% - 10px);
		bottom: 5px;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.artistSquare {
	@include desktop {
		@include SpoqaHanSansNeo(12px, normal, 1.67);
		letter-spacing: -0.2px;
		width: 91px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(6px, normal, 1);
		transform: scale(0.5, 0.5);
		letter-spacing: -0.1px;
		width: 91px;
		margin-left: -14px;
	}
	color: #fff;
	white-space: nowrap;
	overflow : hidden;
}

.openseaBtn {
	@include desktop {
		padding: 6px 10px;
		display: flex;
		background-color: white;
		border-radius: 5px;
	}
	@include mobile {
		padding: 3px 5px;
		display: flex;
		background-color: white;
		border-radius: 2px;
	}
	cursor: pointer;
}

.openseaBtn img {
	@include desktop {
		height: 12px;
		width: 54px;
	}
	@include mobile {
		height: 6px;
		width: 23px;
	}
}

.mobText {
	@include mobile {
		transform: scale(0.5,0.5);
	}
}

.collectBtn {
	@include desktop {
		@include SpoqaHanSansNeo(9px, bold, 1.11);
		letter-spacing: -0.18px;
		padding: 6px 12px;
		border-radius: 5px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(9px, bold, 1.11);
		letter-spacing: -0.18px;
		padding: 0px 0px;
		padding-bottom: 2px;
		border-radius: 2px;
	}
	color: #fff;
	background-color: #ff4f4f;
	cursor: pointer;
}

.showContainter {
	@include desktop {
		width: 223px;
		height: 350px;
		border-radius: 5px;
	}
	@include mobile {
		width: 168px;
		height: 264px;
		border-radius: 4px;
	}
	overflow: hidden;
	position: relative;
}

.showAlbum {
	@include desktop {
		width: 223px;
		height: 246px;
	}
	@include mobile {
		width: 168px;
		height: 185px;
	}
	object-fit: cover;
}

.showDate {
	@include desktop {
		width: 207px;
		height: 18px;
		position: absolute;
		bottom: 99px;
		padding: 6px 8px;
		@include SpoqaHanSansNeo(12px, normal, 1.33);
		letter-spacing: -0.2px;
	}
	@include mobile {
		width: 156px;
		height: 18px;
		position: absolute;
		bottom: 78px;
		padding: 3px 6px;
		@include SpoqaHanSansNeo(10px, normal, 1.6);
		letter-spacing: -0.17px;
	}
	color: #fbfbfb;
	display: flex;
	background-color: #13141a;
	justify-content: flex-start;
	align-items: center;
}

.showTitle {
	@include desktop {
		@include SpoqaHanSansNeo(16px, 600, 1.5);
		letter-spacing: -0.3px;
		width: 215px;
		margin: 14px auto;
	}
	@include mobile {
		@include SpoqaHanSansNeo(13px, 500, 1.54);
		letter-spacing: -0.24px;
		width: 168px;
		margin: 9px auto;
	}
	color: #202429;
	word-break: keep-all;
}

.showContent {
	@include desktop {
		@include SpoqaHanSansNeo(14px, normal, 1.14);
		letter-spacing: -0.23px;
		width: 215px;
		margin: 0px auto;
		margin-bottom: 5px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(9px, normal, 1.78);
		letter-spacing: -0.15px;
		width: 168px;
		margin: 0px auto;
		margin-bottom: 5px;
	}
	color: #202429;
}

.goodsContent {
	@include desktop {
		@include SpoqaHanSansNeo(16px, bold, 1);
		width: 215px;
		margin-bottom: 6px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(12px, bold, 1.33);
		width: 168px;
		margin-bottom: 6px;
		letter-spacing: -0.2px;
	}
	color: #202429;
	margin: 0px auto;
	
}

.wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 2;
	top:0;

}

.nftContainter {
	@include desktop {
		width: 223px;
		height: 350px;
	}
	@include mobile {
		width: 168px;
		height: 264px;
	}
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}


.nftTitle {
	@include desktop {
		@include SpoqaHanSansNeo(16px, 600, 1.5);
		letter-spacing: -0.3px;
		width: 207px;
		margin: 14px auto;
		margin-left: 8px;
		margin-right: 8px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(13px, 600, 1.54);
		letter-spacing: -0.24px;
		width: 156px;
		margin: 8px auto;
		margin-left: 6px;
		margin-right: 6px;
	}
	color: #202429;
	word-break: keep-all;
}

.NFTticker {
	@include desktop {
		width: 22px;
		height: 22px;
		margin: 12px;
	}
	@include mobile {
		width: 16px;
		height: 16px;
		margin: 8px;
	}
	border-radius: 100px;
	position: absolute;
	z-index: 2;
}

.nftContent {
	@include desktop {
		@include SpoqaHanSansNeo(12px, normal, 1.33);
		letter-spacing: -0.2px;
		width: 84px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(9px, normal, 1.78);
		letter-spacing: -0.15px;
		width: 74px;
	}
	color: #202429;
}

.nftUnder {
	@include desktop {
		width: 207px;
		padding: 0px 8px;
		bottom: 6px;
	}
	@include mobile {
		width: 156px;
		padding: 0px 6px;
		bottom: 6px;
	}
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	
}

.nftOpenseaBtn {
	@include desktop {
		padding: 6px 8px;
		border-radius: 5px;
	}
	@include mobile {
		padding: 4px 6px;
		border-radius: 2px;
	}
	display: flex;
	background-color: #13141a;
	
	cursor: pointer;
	z-index: 4;
}

.nftOpenseaBtn img {
	@include desktop {
		height: 13px;
		width: 56px;
	}
	@include mobile {
		height: 9px;
		width: 40px;
	}
	cursor: pointer;
}

.scale {
	@include mobile {
		transform: scale(0.8,0.8);
	}
}

.nftCollectBtn {
	@include desktop {
		@include SpoqaHanSansNeo(12px, 500, 0.83);
		letter-spacing: -0.24px;
		padding: 7px 14px;
		border-radius: 5px;
	}
	@include mobile {
		@include SpoqaHanSansNeo(8px, 500, 1.25);
		letter-spacing: -0.24px;
		padding: 2px 10px;
		border-radius: 2px;
	}
	color: #fff;
	background-color: #ff4f4f;
	cursor: pointer;
	z-index: 4;
}

.detailContainer {
	border-radius: 0px 0px 5px 5px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
	width: 223px;
}

.albumWrapper {
	width: 223px;
	height: 223px;
	position: relative;
}

.albumImg {
	width: 223px;
	height: 223px;
}

.chainIcon {
	position: absolute;
	top: 12px;
	left: 12px;
	height: 22px;
	width: 22px;
}

.rankWrapper {
	padding: 6px;
	background-color: black;
	display: flex;
	justify-content: space-between;
}

.rankLeft {
	@include SpoqaHanSansNeo(14px, 500, 1.14);
	letter-spacing: -0.23px;
	color: #fbfbfb;
}

.rankRight {
	@include SpoqaHanSansNeo(12px, 400, 1.33);
	letter-spacing: -0.23px;
	color: #fbfbfb;
}

.detailWrapper {
	padding: 8px;
}

.artistDetail {
	@include SpoqaHanSansNeo(12px, 400, 1.33);
	letter-spacing: 0.2px;
	color: #202429;
	padding-top: 10px;
}

.artistTitle {
	@include SpoqaHanSansNeo(18px, 600, 1.44);
	letter-spacing: -0.34px;
	color: #202429;
	padding-top: 7px;
}

.ownershipInfo {
	display: flex;
	margin-top: 7px;
	color: #202429;
	align-items: flex-end;
	@include SpoqaHanSansNeo(18px, 600, 1.44);
	letter-spacing: -0.34px;
}

.tokenType {
	height: fit-content;
	@include SpoqaHanSansNeo(12px, 600, 1.7);
	letter-spacing: -0.23px;
	margin-left: 6px;


}

.line {
	height: 1px;
	background-color: #e0e0ec;
	margin: 22px 0px;
}

.listWrapper {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
    padding-bottom: 9px;
}

dt {
	display: list-item;
	list-style-type: "💎";
	padding-inline-start: 1ch;
	margin-inline-start: 8px;
	@include SpoqaHanSansNeo(12px, 400, 1.5);
	word-break: keep-all;
	letter-spacing: -0.23px;
	color: #202429;
	margin-bottom: 11px;
}

dd {
	display: list-item;
	list-style-type: "✔";
	padding-inline-start: 1ch;
	margin-inline-start: 8px;
	@include SpoqaHanSansNeo(12px, 400, 1.5);
	word-break: keep-all;
	letter-spacing: -0.23px;
	color: #202429;
	margin-bottom: 11px;

}

.readMoreBtn {
	@include SpoqaHanSansNeo(14px, 600, 1.21);
	color: #ff424d;
	cursor: pointer;
	text-align: center;
	margin-bottom: 52px;
}

.priceWrapper {
	display: flex;
	align-items: flex-end;
	letter-spacing: -0.23px;
	@include SpoqaHanSansNeo(12px, 400, 1.35);
	color: #202429;
	margin-bottom: 24px;
}

.price {
	@include SpoqaHanSansNeo(18px, 600, 0.89);
	margin-right: 4px;
}

.priceIcon {
	margin-left: 8px;
	margin-right: 4px;
	width: 18px;
	height: 18px;
}

.detailBtn {
	cursor: pointer;
	border: solid 0.5px #000;
	width: 207px;
	height: 43px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SpoqaHanSansNeo(16px, 500, 1);
	border-radius: 5px;
	color: black;
}

.detailBtnBlack img {
	width: 21px;
	height: 21px;
	margin-right: 8px;
}

.detailBtnBlack {
	cursor: pointer;
	width: 207px;
	height: 43px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SpoqaHanSansNeo(14px, 500, 1);
	border-radius: 5px;
	color: white;
	background-color: #151516;
}

.sellAmount {
	text-align: center;
	margin-top: 28px;
	@include SpoqaHanSansNeo(16px, 600, 0.88);
	letter-spacing: -0.23px;
  	color: #acacb2;
}

.artistContainer {
	width: 160px;
	height: 160px;
	padding: 8px;
	background-color: #f6f6f8;
	cursor: pointer;
}

.artistInner {
	width: 160px;
	height: 160px;
	position: relative;
	background-size: cover;
}

.artistNameBox {
	position: absolute;
	bottom: 5px;
	left: 8px;
}

.artistNameBoxUpper {
	background-color: #151516;
	@include Pretendard(18px, 700, normal);
	letter-spacing: 0.2px;
	padding: 3px 6px;
	color: #f6f6f8;
	width: fit-content;
}

.artistNameBoxLower {
	border: solid 1px #000;
	background-color: #f6f6f8;
	@include Pretendard(18px, 700, normal);
	letter-spacing: -0.34px;
	color: #000;
	padding: 2px 4px;
	margin-left: 4px;
	margin-top: -3px;
	width: fit-content;
}