@import "../../../styles/utils.scss";

.superWrapper {
	width: 100vw;
	overflow: hidden;
	padding-top: 80px;
}

.bgWrapper {
	background-size: 100%;
	background-position: center;
}

.blur {
	padding-top: 40px;
	-webkit-backdrop-filter: blur(37px);
  backdrop-filter: blur(37px);
  background-color: rgb(0 0 0 / 28%)
}

.wrapper {
	width: 1080px;
	margin: 0px auto;
	
}

.wrapper2 {
	width: 1080px;
	margin: 0px auto;
}


.container {
	display: flex;
}

.title {
	@include Pretendard(52px, 700, 0.77);
	letter-spacing: -0.98px;
	text-align: center;
	color: #151516;
	padding: 14px 0px;
	margin-bottom: 80px;
}

.leftSide {
	width: 523px;
	height: 500px;
	margin-left: 70px;
	position: relative;
	padding-top: 1px;
}

.album {
	width: 438px;
	height: 438px;
	margin-top: 30px;
	margin-left: 53px;
	border: 1px solid black;
}

.album img {
	width: 470px;
	height: 470px;
	object-fit: cover;
}

.detailInfo {
	width: 404px;
	height: 396px;
	border: #181818 2px solid;
	position: absolute;
	background-color: white;
	margin-left: 23px;
	padding: 20px 18px;
}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.detailTitleNoLoop {
	padding-top: 16px;
	padding-bottom: 10px;
	width: 400px;
	@include Pretendard(24px, 700, 0.92);
	letter-spacing: -0.34px;
	color: #202429;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
}


.detailTitle {
	padding-top: 16px;
	padding-bottom: 10px;
	width: 400px;
	@include Pretendard(24px, 700, 0.92);
	letter-spacing: -0.34px;
	color: #202429;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
}

.detailTitle div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.mintDatas {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mintDatasBottom {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 37px;
	align-items: center;
}

.mintDetails {
	margin-top: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 22px;
}

.strongTitle {
	@include Pretendard(18px, 600, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
}

.dataTitle {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #43434a;
}

.dataString {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #151516;
}

.rorWrapper {
	display: flex;
	margin-top: 20px;
	justify-content: space-between;
}

.rorRate {
	@include Pretendard(20px, 500, 1.3);
	letter-spacing: -0.38px;
	color: #202429;
	margin-top: 18px;
}

.rorElement {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rorElementLast {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.rorTitle {
	@include Pretendard(14px, 500, 1.43);
	letter-spacing: -0.2px;
	color: #202429;
	display: flex;
	align-items: center;
}

.rorTitle img {
	width: 26px;
	height: 26px;
	object-fit: contain;
	margin-right: 4px;
}

.dday {
	padding: 2px 6px;
	border: solid 1px #000;
	background-color: #ff424d;
	@include SpoqaHanSansNeo(14px, 700, 1.43);
	letter-spacing: -0.2px;
	color: #202429;
}

.rightSide {
	width: 440px;
	height: 365px;
	padding: 0 42px;
	position: relative;
	padding-top: 36px;
	padding-bottom: 186px;
}

.rightSub {
	@include Pretendard(16px, 400, 2);
	letter-spacing: -0.27px;
	color: #f6f6f8;
}

.rightTitle {
	@include Pretendard(36px, 700, 1.44);
	color: #f6f6f8;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
}

.rightTitleNoLoop {
	@include Pretendard(36px, 700, 1.44);
	color: #f6f6f8;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
}

.rightTitle div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.rightType {
	@include Pretendard(36px, 700, 1.44);
	color: #f6f6f8;
	margin-bottom: 47px;
}

.btnList {
	display: flex;
	margin-top: 40px;
}

.viewBtn {
	@include Pretendard(17px, 400, normal);
	color: #fff;
	padding: 12px 24px;
	border-radius: 4px;
	background-color: #151516;
	margin-right: 32px;
	cursor: pointer;
}

.investBtn {
	@include Pretendard(17px, 400, normal);
	color: #151516;
	padding: 12px 24px;
	border-radius: 4px;
	border: solid 1px #151516;
	background-color: #fff;
	cursor: pointer;
}

.dropsTitle {
	margin-top: 75px;
	display: flex;
	align-items: flex-end;
	margin-bottom: 33px;
}

.dropsMain {
	@include Pretendard(36px, 800, 1.11);
	letter-spacing: -0.67px;
	color: #151516;
}

.dropsSub {
	margin-left: 16px;
	@include Pretendard(16px, 400, 1.38);
	letter-spacing: -0.3px;
	color: #43434a;
}

.cardWrapperSelected {
	width: 238px;
	height: 159px;
	border: 1px solid black;
	position: relative;
	background-size: cover;
	box-shadow: 8px 10px 0px 2px #000000;
	cursor: pointer;
	transition-duration: 0.3s;
}

.cardWrapper {
	width: 238px;
	height: 159px;
	border: 1px solid black;
	position: relative;
	background-size: cover;
	cursor: pointer;
	transition-duration: 0.3s;
}

.cardTitle {
	position: absolute;
	top: 10px;
	left: 12px;
}

.cardInfo {
	position: absolute;
	bottom: 7px;
	left: 9px;
}

.titleInfo {
	@include Pretendard(16px, 700, 1.31);
	margin-bottom: 6px;
	letter-spacing: -0.27px;
	color: #fff;
	width: 222px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

}

.artistInfo {
	@include Pretendard(12px, 500, 1.67);
	letter-spacing: -0.2px;
	color: #fbfbfb;
	width: 222px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.swiperContainer {
	width: 100%;
	margin: 0px auto;
	clip-path: inset( -100vw -100vw -100vw 0 );
	margin-bottom: 239px;
}