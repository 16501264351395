@import "../../styles/utils.scss";

.red {
  @include Pretendard(12px, 500, 1.67);
  letter-spacing: -0.17px;
  text-align: right;
  color: #ff424d;
}

.blue {
  @include Pretendard(12px, 500, 1.67);
  letter-spacing: -0.17px;
  text-align: right;
  color: #404eed;
}

.grey {
  color: #babbc0;
}

.times {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    @include mobile {
      margin-bottom: 24px;
    }

    @include desktop {
      width: 332px;
    }

    .timeBox {
      display: inline-block;

      :first-child {
        color: #1b1c23;
        @include mobile {
          @include Montserrat(36px, 500, 1.17);
          color: #1b1c23;
        }

        @include desktop {
          @include Montserrat(42px, 500, 1);
          width: 56px;
          height: 41px;
          display: flex;
          margin-bottom: 6px;
          align-items: center;
          justify-content: center;
          color: #1b1c23;

        }
      }

      :last-child {
        color: #848589;
        @include mobile {
          @include SpoqaHanSansNeo(12px, normal, 1.33);
          letter-spacing: -0.2px;
          color: #848589;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @include desktop {
          @include SpoqaHanSansNeo(12px, 400, 2.5);
          letter-spacing: -0.2px;
          width: 56px;
          height: 22px;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .colons {
    :first-child {
      @include desktop {
        width: 21px;
        height: 100%;
        @include Montserrat(28px, 500, 0.88);
        margin-top: 8px;
      }
      @include mobile {
        width: 100%;
        height: 100%;
        @include Montserrat(36px, 500, 0.88);
        margin-top: 2px;
      }
    }
  }
  