@import "../../../styles/utils.scss";

.wrapper {
	width: 1080px;
	padding-top: 221px;
	padding-bottom: 120px;
	margin: 0px auto;
}

.titleWrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-bottom: 16px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 22px;
}

.title {
	@include Pretendard(42px, 700, 0.95);
	letter-spacing: -0.79px;
	color: #151516;
}

.docsBtn {
	@include Pretendard(16px, 500, 1);
	letter-spacing: -0.3px;
	color: #151516;
	display: flex;
	align-items: flex-end;
	cursor: pointer;
}

.docsBtn img {
	margin-left: 6px;
}

.typesWrapper {
	display: flex;
	align-items: flex-start;
	margin-bottom: 60px;
}

.types {
	@include Pretendard(17px, 400, 1);
	letter-spacing: -0.32px;
	color: #151516;
	margin-right: 28px;
}

.btnList {
	display: flex;
	flex-wrap: wrap;
}

.active {
	cursor: pointer;
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.26px;
	text-align: center;
	color: #f6f6f8;
	padding: 4px 12px 3px;
	border-radius: 2px;
	background-color: #151516;
	border: solid 1px #151516;
	margin-right: 20px;
	margin-bottom: 10px;
}

.inactive {
	cursor: pointer;
	@include Pretendard(14px, 400, 1);
	letter-spacing: -0.26px;
	text-align: center;
	color: #18191a;
	padding: 4px 12px;
	border-radius: 2px;
	border: solid 1px #747576;
	margin-right: 20px;
	margin-bottom: 10px;
}

.elemWrapper {
	width: 338px;
	cursor: pointer;
	margin-bottom: 45px;
}

.videoBox {
	width: 338px;
	height: 189px;
	background-size: cover;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	position: relative;
}

.dummy {
	width: 338px;
	margin-bottom: 45px;
}

.redBox {
	@include Pretendard(12px, 700, 1);
	letter-spacing: -0.23px;
	text-align: center;
	color: #000;
	border: solid 1px #000;
	background-color: #ff424d;
	width: 60px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 12px;
	left: 14px;
}

.blueBox {
	@include Pretendard(12px, 700, 1);
	letter-spacing: -0.23px;
	text-align: center;
	color: #f6f6f8;
	border: solid 1px #000;
	background-color: #404eed;
	width: 60px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 12px;
	left: 14px;
}

.playbtn {
	width: 37px;
	height: 37px;
	margin: 76px 150px;
}

.elemTitle {
	@include Pretendard(20px, 500, 1);
	letter-spacing: -0.38px;
	color: #18191a;
	margin-top: 24px;
	margin-bottom: 7px;
}

.elemDetail {
	@include Pretendard(16px, 500, 1.69);
	letter-spacing: -0.3px;
	color: #18191a;
}

.elemList {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	min-height: 678px;
}