.container {
	width: calc(100% - 32px);
	height: 34px;
	padding: 24px 16px;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
	overflow:  hidden;
	position: relative;
  background-color: #000;
}

.noInvert {
  filter: invert(0%) !important;
}

.container img {
  width: 103px;
  height: 35px;
  object-fit: contain;
  filter: invert(100%);
  object-position: center;
}

.invert {}

.elemWraper {
  display: flex !important;
  align-items: center;
  justify-content: center;
}