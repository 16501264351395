@import "../../../styles/utils.scss";

.container {
	text-align: center;
	background-color: white;
	padding: 90px 0 58px;
}

.miniTitle {
	@include Montserrat(14px, 400, 1.14);
	letter-spacing: -0.26px;
	color: #18191a;
}

.title {
	@include Pretendard(42px, 800, 0.95);
	color: #151516;
	padding: 8px 0px;
	margin-bottom: 42px;
}

.tabGroup {
	border: solid 1px #151516; 
	display: flex;
	width: 391px;
	margin: 0px auto;
	margin-bottom: 88px;
}

.tabBtnActive {
	background-color: #151516;
	color: #f6f6f8;
	@include Pretendard(14px, 400, 1.14);
	letter-spacing: -0.26px;
	width: 131px;
	padding: 9px 0px;
	cursor: pointer;
	z-index: 3;
}


.tabBtn {
	@include Pretendard(14px, 400, 1.14);
	letter-spacing: -0.26px;
	color: #151516;
	width: 131px;
	padding: 9px 0px;
	cursor: pointer;
	z-index: 3;
}

.tabBtnMiddle {
	@include Pretendard(14px, 400, 1.14);
	letter-spacing: -0.26px;
	color: #151516;
	border-left: solid 1px #151516; 
	border-right: solid 1px #151516;
	width: 129px;
	padding: 9px 0px;
	cursor: pointer;
	z-index: 3;
}

.outerSec {
	position: relative;
}

.section {
	width: 1080px;
	height: fit-content;
	margin: 0px auto;
	padding-bottom: 107px;
	z-index: 2;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.redLine {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 700px;
	bottom: 0px;
}

.redLine img {
	z-index: 0;
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.leftSide {
	width: 523px;
	text-align: left;
}

.leftSideHidden {
	width: 523px;
	text-align: left;
}

.secTitle {
	@include Pretendard(36px, 700, 1.5);
	color: #151516;
	margin-bottom: 36px;
	transform: translateY(0%);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.secTitleHidden {
	@include Pretendard(36px, 700, 1.5);
	color: #151516;
	margin-bottom: 36px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}


.leftSide * {
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.secContent {
	@include Pretendard(22px, 400, 1.55);
	color: #13141a;
	margin-bottom: 6px;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.leftSideHidden .secContent {
	@include Pretendard(22px, 400, 1.55);
	color: #13141a;
	margin-bottom: 6px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.proviso {
	@include Pretendard(15px, 400, 1.47);
	color: #5b5b5b;
	margin-bottom: 58px;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.leftSideHidden .proviso {
	@include Pretendard(15px, 400, 1.47);
	color: #5b5b5b;
	margin-bottom: 58px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.btnList {
	display: flex;
	color: white
}

.detailBtn {
	@include Pretendard(17px, 500, normal);
	color: #fff;
	padding: 12px 24px;
	background-color: #151516;
	cursor: pointer;
	margin-right: 38px;
	z-index: 3;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
	opacity: 1;
}

.detailBtHidden {
	@include Pretendard(17px, 500, normal);
	color: #fff;
	padding: 12px 24px;
	background-color: #151516;
	cursor: pointer;
	margin-right: 38px;
	z-index: 3;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
	opacity: 0;
}


.infoBtn {
	@include Pretendard(16px, 400, 1);
	letter-spacing: -0.3px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.infoBtn img {
	margin-right: 12px;
}
.rightSide {
	width: fit-content;
	z-index: 2;
}

.rightSideHidden {
	width: fit-content;
	z-index: 2;
}
.rightSideImg {
	margin-top: 36px;
	width: 494px;
	height: 372px;
	object-fit: contain;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.rightSideImgHidden {
	margin-top: 36px;
	width: 494px;
	height: 372px;
	object-fit: contain;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.rightSideHidden div {
	margin-top: 36px;
	margin-right: -30px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.rightSide div {
	margin-top: 36px;
	margin-right: -30px;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}