@import "../../../styles/utils.scss";

.liner {
    border-top: #000 1px solid;
}

.container {
    width: 1080px;
	margin: 0px auto;
    display: flex;
    padding: 40px 0px;
    flex-wrap: wrap;
}

.indicatorElem {
    width: calc(25% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 28px;
}

.indicatorElemWithBorder {
    width: calc(25% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 28px;
    border-right: 1px solid #000;
}

.indicatorElemWithBorderLeft {
    width: calc(25% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 8px;
    padding-left: 0px;
    border-right: 1px solid #000;
}

.elemTitle {
    @include Pretendard(36px, 700, 1);
    letter-spacing: -0.67px;
    color: #151516;
}

.elemSub {
    @include Pretendard(14px, 400, 14px);
    letter-spacing: -0.2px;
    color: #151516;
    margin-top: 8px;
}

.elemIndi {
    color: #151516;
    @include Pretendard(36px, 500, 0.78);
    margin-bottom: 20px;
}

.elemIndi strong {
    font-weight: 500 !important;
}
.elemHeading {
    color: #63626B;
    @include Pretendard(14px, 500, 14px);
    letter-spacing: -0.2px;
    margin-bottom: 16px;
}
.elemComm {
    color: #BABBC0;
    @include Pretendard(14px, 400, 14px);
    letter-spacing: -0.2px;
}