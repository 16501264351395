@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 100;
  src: url(./styles/fonts/SpoqaHanSansNeo-Thin.ttf) format('truetype')
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 300;
  src: url(./styles/fonts/SpoqaHanSansNeo-Light.ttf) format('truetype')
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  src: url(./styles/fonts/SpoqaHanSansNeo-Regular.ttf) format('truetype')
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  src: url(./styles/fonts/SpoqaHanSansNeo-Medium.ttf) format('truetype')
}


@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  src: url(./styles/fonts/SpoqaHanSansNeo-Bold.ttf) format('truetype')
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(./styles/fonts/Montserrat-Bold.ttf) format('truetype')
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(./styles/fonts/Montserrat-SemiBold.ttf) format('truetype')
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url(./styles/fonts/Montserrat-Medium.ttf) format('truetype')
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(./styles/fonts/Montserrat-Regular.ttf) format('truetype')
}


@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url(./styles/fonts/Pretendard-Bold.ttf) format('truetype')
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src: url(./styles/fonts/Pretendard-SemiBold.ttf) format('truetype')
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url(./styles/fonts/Pretendard-Medium.ttf) format('truetype')
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src: url(./styles/fonts/Pretendard-Regular.ttf) format('truetype')
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src: url(./styles/fonts/Pretendard-Light.ttf) format('truetype')
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;
  src: url(./styles/fonts/Pretendard-Thin.ttf) format('truetype')
}
