@import "../../styles/utils.scss";

.cardType {
	padding: 4px 6px;
	padding-bottom: 5px;
	@include Pretendard(12px, 600, normal);
	letter-spacing: -0.23px;
	color: white;
	width: fit-content;
	background-color: #131415;
}

.cardArtist {
	padding: 3px 6px;
	@include Pretendard(12px, 700, normal);
	border: solid 1px #000;
	background-color: white;
	letter-spacing: -0.23px;
	width: fit-content;
	color: #131415;
	margin-left: 6px;
	margin-top: -3px;
}

.cardArtistRed {
	padding: 3px 6px;
	@include Pretendard(12px, 700, normal);
	border: solid 1px #000;
	background-color: #ff424d;
	letter-spacing: -0.23px;
	width: fit-content;
	color: #131415;
	margin-left: 6px;
	margin-top: -3px;
}

.cardArtistBlack {
	padding: 3px 6px;
	@include Pretendard(12px, 700, normal);
	background-color: #000;
	letter-spacing: -0.23px;
	color: white;
	border: solid 1px #000;
	width: fit-content;
	margin-left: 6px;
	margin-top: -3px;
}

.cardTypeBlue {
	padding: 3px 6px;
	padding-bottom: 4px;
	border: solid 1px #000;
	@include Pretendard(12px, 600, normal);
	letter-spacing: -0.23px;
	color: white;
	width: fit-content;
	width: fit-content;
	background-color: #104eed;
}