@import "../../../styles/utils.scss";

.contents {
    width: 690px;
    margin-right: 51px;
    padding-top: 50px;
    line-height: 1.8;
    padding-bottom: 50px;
}

.title {
    @include Pretendard(42px, bold, normal);
    letter-spacing: -0.6px;
    color: #151516;
    margin-bottom: 60px;
}

.about {
    @include Pretendard(24px, 600, normal);
    letter-spacing: -0.34px;
    color: #151516;
    margin-bottom: 28px;
}

.aboutText {
    @include Pretendard(18px, normal, 1.7);
    letter-spacing: -0.23px;
    color: #151516;
    margin-bottom: 100px;
}

.readMore {
    color: #1109c4;
    cursor: pointer;
}

.roadmapTitle {
    @include Pretendard(24px, 600, normal);
    letter-spacing: -0.34px;
    color: #151516;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 28px;
}

.roadmapContainter {
    margin-left: 16px;
    border-left: solid 1px #979797;
    padding-right: 20px;
    padding-top: 6px;
}

.nemo {
    width: 12px;
    height: 12px;
    background-color: #151516;
    margin-top: 6px;
    margin-left: -6px;
    margin-right: 28px;
}

.roadmapElem {
    display: flex;
    margin-bottom: 60px;
}

.roadmapElemTitle {
    @include Pretendard(18px, 500, 1.33);
    letter-spacing: -0.23px;
    color: #151516;
    margin-bottom: 24px;
}

.roadmapElemLine {
    display: flex;
    margin-bottom: 18px;
}

.roadmapElemSubtitle {
    @include Pretendard(16px, 500, 1.5);
    letter-spacing: -0.2px;
    color: #5c5c5c;
    margin-right: 14px;
    width: 68px;
}
.roadmapElemText {
    @include Pretendard(16px, normal, 1.5);
    letter-spacing: -0.2px;
    color: #151516;
}

.roadmapElemText p {
    margin: 0px;
}

.aboutTitle {
    @include Pretendard(24px, 600, normal);
    letter-spacing: -0.36px;
    color: #151516;
    margin-bottom: 28px;
}

.aboutHidden {
    height: 600px;
    overflow: hidden;
    position: relative;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 100%);
}

.moreBtn {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 40px);
    width: 81px;
    border-radius: 4px;
    box-shadow: 4px 4px 0 0 #000;
    border: solid 1px #151516;
    background-color: #fff;
    padding: 12px 24px;
    @include Pretendard(17px, 400, normal);
    color: #151516;
    cursor: pointer;
}