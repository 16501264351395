@import "../../styles/utils.scss";

.toggleBtn {
	.label {
		margin-top: 40px;
		@include SpoqaHanSansNeo(12px, 600, 1.67);
		letter-spacing: -0.2px;
		color: #18191a;
		margin-bottom: 12px;
	}

	.btnList {
		display: flex;
	}

	.selected {
		padding: 10px 18px;
		border-radius: 20px;
		background-color: #ff424d;
		@include SpoqaHanSansNeo(14px, 600, normal);
		letter-spacing: -0.2px;
		margin-right: 12px;
		cursor: pointer;
	}

	.nonSelected {
		padding: 10px 18px;
		border-radius: 20px;
		background-color: #f7f7f7;
		@include SpoqaHanSansNeo(14px, 600, normal);
		letter-spacing: -0.2px;
		margin-right: 12px;
		cursor: pointer;
	}
}

.tabBtn {
	width: 100%;

	.btnList {
		width: 100%;
		display:flex;
	}

	.selected {
		@include desktop {
			@include SpoqaHanSansNeo(16px, bold, 1);
			padding-bottom: 16px;
			letter-spacing: -0.3px;
			cursor: pointer;
			display: flex;
			align-items: center;
			margin-right: 40px;
		}
		@include mobile {
			@include SpoqaHanSansNeo(12px, 500, 1.33);
			padding-bottom: 6px;
			letter-spacing: -0.23px;
			cursor: pointer;
			display: flex;
			align-items: center;
		}
	}

	.selected:last-child {
		margin-right: 0px;
	}

	.nonSelected {
		@include desktop {
			@include SpoqaHanSansNeo(16px, bold, 1);
			padding-bottom: 16px;
			letter-spacing: -0.3px;
			cursor: pointer;
			display: flex;
			align-items: center;
			margin-right: 40px;
		}
		@include mobile {
			@include SpoqaHanSansNeo(12px, normal, 1.33);
			padding-bottom: 6px;
			letter-spacing: -0.3px;
			cursor: pointer;
			display: flex;
			align-items: center;
		}
	}

	.nonSelected:last-child {
		margin-right: 0px;
	}

	.bottomLine {
		width: 100%;
		height: 3px;
		margin-top: -3px;
	}
}