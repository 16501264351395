@import "../../styles/utils.scss";

.tabMenu {
	width: 1080px;
	margin: 0px auto;
	display: flex;
}

.menuElemActive {
	padding: 5px 3px;
	@include Pretendard(20px, 600, normal);
	letter-spacing: -0.29px;
	color: #151516;
	border-bottom: 4px #ff424d solid;
	width: fit-content;
	margin-right: 39px;
	cursor: pointer;
}

.menuElem {
	padding: 5px 3px;
	@include Pretendard(20px, 600, normal);
	letter-spacing: -0.29px;
	color: #151516;
	width: fit-content;
	margin-right: 39px;
	cursor: pointer;
}

.line {
	width: 100%;
	height: 1px;
	background-color: #151516;
}