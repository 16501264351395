@import "../../../styles/utils.scss";

.wrapper {
	width: 1080px;
    padding-bottom: 200px;
	margin: 0px auto;
    min-height: 800px;
}

.subtitleEng {
	@include Pretendard(24px, 600, normal);
	letter-spacing: -0.34px;
	color: #151516;
}

.subtitleKr {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-left: 8px;
	margin-bottom: 4px;
}

.subtitleDiv {
	display: flex;
	align-items: flex-end;
	margin-top: 37px;
	padding-bottom: 18px;
    border-bottom: 2px black solid;
}

.listWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 24px;
    cursor: pointer;
}

.listTitle {
    display: flex;
    align-items: center;
    @include Pretendard(24px, 600, normal);
}

.ltLeft {
    margin-right: 32px;
    letter-spacing: -0.34px;
    color: #151516;
}
.ltRight{
    @include Pretendard(22px, 500, normal);
    letter-spacing: -0.31px;
    color: #43434a;
}

.arrow img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}

.barContainer {
    border-bottom: 2px black solid;
}

.elemWrap {
    display: flex;
    padding: 48px 15px;
    margin-top: -12px;
    flex-flow: wrap;
    gap: 25px 41px; 
    height: fit-content;
    max-height: 1000px;
    transition: 1s;  
    overflow: hidden;
}
.elemHidden {
    display: flex;
    padding: 0px 15px;
    flex-flow: wrap;
    gap: 25px 41px; 
    transition: 1s;  
    max-height: 0px;
    overflow: hidden;
}
.album{
    width: 175px;
    height: 175px;
    position: relative;
}

.tag {
    position: absolute;
    top: 10px;
    left: 8px;
}

.elem {
    border: 1px solid black;
}

.elemTitle {
    padding: 0px 6px;
    @include Pretendard(14px, 600, 1.71);
    letter-spacing: -0.26px;
    color: #202429;
    margin-top: 4px;
}

.elemId {
    padding: 0px 6px;
    @include Pretendard(14px, 600, 1.71);
    letter-spacing: -0.26px;
    color: #202429;
}

.bottomInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 6px;
    margin-top: 13px;
    padding-bottom: 6px;
}

.leftTop {
    @include Pretendard(10px, 400, 1.6);
    letter-spacing: -0.17px;
    color: #202429;
}

.leftBottom {
    @include Pretendard(13px, 500, 1.54);
    letter-spacing: -0.19px;
    color: #202429;
}

.rightBottom {
    @include Pretendard(13px, 500, 1.23);
    letter-spacing: -0.24px;
    color: #181818;
    text-decoration: underline;
    cursor: pointer;
}