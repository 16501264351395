@import "../../../styles/utils.scss";


.wrapper  {
	margin-top: 80px;
	background: linear-gradient(to bottom, #181818 0%,#181818 71.68%, white 28.32%, white 100% );
	height: 604px;
}

.container {
	width: 1080px;
	margin: 0px auto;
	display: flex;
	padding-top: 40px;
	position: relative;
}

.album {
	margin-left: 109px;
	width: 490px;
	height: 490px;
	background-size: cover;
}

.timer {
	margin: 0px auto;
	margin-top: 384px;
	width: 376px;
  	height: 92px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	background-blend-mode: lighten;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
}

.rightSide {
	width: 339px;
	padding-top: 24px;
	margin-left: 141px;
}

.period {
	width: fit-content;
	padding: 5px 6px;
	@include Pretendard(18px, 700, normal);
	letter-spacing: -0.34px;
	text-align: center;
	color: #000;
	background-color: #ff424d;
	border: 1px solid black;
}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.title {
	margin-top: 24px;
	width: 339px;
	@include Pretendard(36px, 700, normal);
	color: #f6f6f8;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);

}

.title div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.dataTitle {
	@include SpoqaHanSansNeo(42px, 400, normal);
	color: #f6f6f8;
	margin-top: 40px;
}


.subTitle {
	margin-top: 4px;
	@include Pretendard(16px, 400, 1.38);
	letter-spacing: -0.23px;
	color: #f6f6f8;
}

.titleNoLoop {
	margin-top: 24px;
	width: 339px;
	@include Pretendard(36px, 700, normal);
	color: #f6f6f8;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
}


.btns {
	margin-top: 66px;
	display: flex;
	position: relative;
}

.btnBg {
	border-radius: 4px;
	background-color: #fff;
	position: relative;
}

.btnBg2 {
	border-radius: 5px;
	border: solid 1px #151516;
	background-color: white;
	position: relative;
}

.btn1 {
	width: 149px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(17px, 500, normal);
	color: #fff;
	border-radius: 4px;
	border: solid 1px #151516;
	cursor: pointer;
	background-color: #151516;
	transition-duration: 0.3s;
	transform: translate3d(-4px, -4px, 0);
}

.btn1:hover {
	transform: translate3d(0px, 0px, 0);
}



.btn2 {
	width: 149px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(17px, 500, normal);
	color: #151516;
	border-radius: 4px;
	border: solid 1px #151516;
	cursor: pointer;
	background-color: #fff;
	transition-duration: 0.3s;
}


.btn1 img, .btn2 img {
	width: 17px;
	margin-left: 6px;
}

.empty {
	width: 33px;
}

.shareBox {
	position: absolute;
	top: 55px;
	max-height: 0px;
	left: 0px;
	border: 0px solid black;
	background-color: white;
	z-index: 5;
	@include Pretendard(14px, 500, 1.14);
	letter-spacing: -0.26px;
	color: #151516;
	overflow: hidden;
	transition: all 1s ease;
}

.shareBoxHover {
	position: absolute;
	top: 55px;
	max-height: 200px;
	left: 0px;
	border: 1px solid black;
	background-color: white;
	z-index: 5;
	@include Pretendard(14px, 500, 1.14);
	letter-spacing: -0.26px;
	color: #151516;
	overflow: hidden;
	transition: all 1s ease;
}


.shareBoxHover img, .shareBox img {
	width: 22px;
	margin-left: 15px;
	margin-right: 14px;
}

.shareBoxElem {
	width: 148px;
	height: 46px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.shareBoxElem:hover {
	background-color: #f5f5f5;
}

.circle {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	animation: 3s ease 0s infinite normal none running pulse
}

.circle2 {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	border: solid 1px #151516;
}

.stage {
	@include Pretendard(32px, 500, 0.63);
	letter-spacing: -0.46px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stage2 {
	@include Pretendard(32px, 500, 0.63);
	letter-spacing: -0.46px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-shadow: -1px 0 #151516, 0 1px #151516, 1px 0 #151516, 0 -1px #151516;
}

@keyframes pulse {
	0% {
		box-shadow: rgba(52, 199, 123) 0px 0px 0px 0px;
	}
	70% {
		box-shadow: rgba(52, 199, 123, 0) 0px 0px 0px 7px;
	}
	100% {
		box-shadow: rgba(52, 199, 123, 0) 0px 0px 0px 0px;
	}
}