@import "../../../../styles/utils.scss";

.container {
    border-top: 1px solid #000;
    display: flex;
    padding: 24px 16px;
    flex-wrap: wrap;
    gap: 24px;
    row-gap: 0px;
}

.indicatorElem {
    width: calc(50% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.indicatorElemWithBorder {
    width: calc(50% - 12px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: 1px solid #000;
}

.elemTitle {
    @include Pretendard(24px, 700, 1);
    letter-spacing: -0.45px;
    color: #151516;
}

.elemSub {
    @include Pretendard(14px, 400, 18px);
    letter-spacing: -0.262px;
    color: #151516;
    margin-top: 8px;
    margin-bottom: 24px;
}

.elemIndi {
    color: #151516;
    @include Pretendard(24px, 500, 28px);
    margin-bottom: 8px;
}

.elemIndi strong {
    font-weight: 500 !important;
}
.elemHeading {
    color: #63626B;
    @include Pretendard(12px, 500, 14px);
    letter-spacing: -0.171px;
    margin-bottom: 8px;
}
.elemComm {
    color: #BABBC0;
    @include Pretendard(12px, 400, 14px);
    letter-spacing: -0.171px;
}