@import "../../../styles/utils.scss";

.title {
    @include Pretendard(42px, bold, normal);
    letter-spacing: -0.6px;
    color: #151516;
    margin-bottom: 60px;
}

.subtitle{
    @include Pretendard(24px, 600, normal);
    letter-spacing: -0.34px;
    color: #151516;
    margin-bottom: 24px;
}

.contents {
    width: 690px;
    margin-right: 51px;
    padding-top: 50px;
    line-height: 1.8;
    padding-bottom: 50px;
}

.moreBtn {
    border-radius: 4px;
    box-shadow: 4px 4px 0 0 #000;
    border: solid 1px #151516;
    background-color: #fff;
    padding: 12px 24px;
    @include Pretendard(17px, 400, normal);
    color: #151516;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
}

.detailTitle {
    padding-top: 30px;
    @include Pretendard(24px, 600, normal);
    letter-spacing: -0.36px;
    color: #151516;
    margin-bottom: 24px;
}

.detailElem {
    display: flex;
    justify-content: space-between;
    @include Pretendard(18px, 400, 1.33);
    letter-spacing: -0.23px;
    color: #151516;
    margin-bottom: 14px;
    white-space: pre-line;
    text-align: right;
}

.aboutText {
    @include Pretendard(18px, 400, 1.5);
    letter-spacing: -0.23px;
    color: #151516;
}