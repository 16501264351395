@import "../../styles/utils.scss";

.container {
	width: 100vw;
	height: 59px;
	display: flex;
	position: fixed;
	bottom: 0;
	z-index: 99;
	background: white;
}

.menuItemActive img{
	width: 28px;
	height: 28px;
	margin-bottom: 2px;
	fill: #18191a;

}

.menuItem, .menuItemActive {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.menuItem img {
	width: 28px;
	height: 28px;
	margin-bottom: 2px;
}

a {
	text-decoration: none;
}

.menuItem div, .menuItemActive div {
	@include SpoqaHanSansNeo(9px, 500, 1.11);
	letter-spacing: -0.2px;
	color: #747576;
	text-decoration: none;
}