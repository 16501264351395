@import "../../../styles/utils.scss";

.modalBg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(21, 21, 22, 0.8);
}

.modal {
	width: 475px;
	background-color: white;
	border: 1px solid #181818;
	padding: 19px 25px;
	margin: 183px auto;
	position: relative;
}

.modalPayment {
	width: 340px;
	background-color: white;
	border: 1px solid #181818;
	padding: 19px 25px;
	margin: 183px auto;
	position: relative;
}

.modalTitlePayment {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	text-align: center;
	color: #202429;
	margin-bottom: 39px;
}

.modalTitle {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	text-align: center;
	color: #202429;
	margin-bottom: 80px;
}

.eventImg {
	width: 64px;
	height: 64px;
	object-fit: contain;
	margin: 0px auto;
	display: block;
	margin-bottom: 12px;
}

.eventName {
	@include Pretendard(20px, 500, 1);
	letter-spacing: -0.29px;
	text-align: center;
	color: #202429;
	margin-bottom: 8px;
}

.eventAmt {
	@include Pretendard(14px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 34px;
}

.eventDetailTitle {
	@include Pretendard(18px, 600, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
	margin-bottom: 12px;
}

.eventDetail {
	@include Pretendard(14px, 500, 1.5);
	letter-spacing: -0.2px;
	color: #151516;
	margin-bottom: 36px;
}

.doneBtn {
	cursor: pointer;
	width: calc(100% - 20px);
	padding: 10px;
	@include Pretendard(17px, 400, normal);
	text-align: center;
	color: #151516;
	border: 1px black solid;
	box-shadow: 3px 4px 0px 1px #000000;
}

.close {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 21px;
	right: 25px;
}

.close img {
	width: 20px;
	height: 20px;
	object-fit: contain;
}

.modalTypeBox {
	padding: 4px 6px;
	@include Pretendard(16px, 600, normal);
	letter-spacing: -0.3px;
	text-align: center;
	color: #000;
	border: 1.5px black solid;
	width: fit-content;
	margin-left: 21px;
	margin-bottom: 20px;
}

.modalPriceBox {
	@include SpoqaHanSansNeo(36px, 400, normal);
	text-align: center;
	color: #151516;
	margin-bottom: 2px;
}

.modalPerBox {
	@include Pretendard(12px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 71px;
}

.modalLine {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.balanceLine {
	margin-bottom: 54px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineLast {
	padding-bottom: 12px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineTitle {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
}

.amtBox {
	display: flex;
	align-items:center;
	justify-content: center;
}

.blackBtn {
	background-color: #181818;
	width: 29px;
	height: 29px;
	color: #f6f6f8;
	display: flex;
	align-items:center;
	justify-content: center;
	cursor: pointer;
}

.amt {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	text-align: center;
	color: #404eed;
	width: 50px;
	height: 27px;
	border: 0px;
	display: flex;
	align-items:center;
	justify-content: center;
	border-top: solid 1px #181818;
	border-bottom: solid 1px #181818;
}

.modalLineValue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #43434a;
}

.modalLineValueBlue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #404eed;
}

.balanceBox {
	border: solid 1px #181818;
	padding: 6px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 222px;
}

.balanceLeft {
	display: flex;
	align-items: center;
}

.balanceImg {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 8px;
}

.balanceTicker {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.balanceAmt {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.buyBtn {
	@include Pretendard(17px, 400, normal);
	color: #151516;
	border-radius: 4px;
	border: solid 1px #151516;
	text-align: center;
	padding: 10px 0px;
	box-shadow: 4px 4px 0px 0px #000000;
	margin-bottom: 4px;
	cursor: pointer;
}

.holderSub {
	@include Pretendard(12px, 400, normal);
	letter-spacing: -0.17px;
	color: #babbc0;
	padding-left: 8px;
}

.inline {
	display: flex;
	justify-content: space-between;
}

.buttons {
	margin-bottom: 20px;
	margin-top: 37px;
	display: flex;
	align-items: center;
}

.rbtn {
	margin-top: -2px;;
	cursor: pointer;
	transform: rotate(180deg);
	margin-right: 6px;
}
.lbtn {
	cursor: pointer;
}

.insufficent {
    color: #ff424d;
}

.noBal {
    @include Pretendard(12px, 500, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    margin-top: -50px;
    margin-bottom: 33px;
}

.charge {
    text-decoration: underline;
    cursor: pointer;
}

.prev {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 25px;
}

.prev img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.minting {
    width: 146px;
    height: 32px;
    margin: 0px auto;
    background-color: #000000;
    @include Pretendard(14px, 500, normal);
    letter-spacing: -0.2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 41px;
}

.profilePic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.address {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tolist {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 51px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 36px;
    color: #151516;
    @include Pretendard(12px, 500, normal);
}

.left {
    width: 17px;
    height: 17px;
    object-fit: contain;
}

.col {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tableTitle {
    @include Pretendard(12px, 600, normal);
    letter-spacing: -0.2px;
    color: #151516;
}

.tableElem {
    @include Pretendard(12px, 600, normal);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.maxgas {
    @include Pretendard(12px, 400, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 110px;
}

.noBal2 {
    @include Pretendard(12px, 500, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    margin-top: -108px;
    margin-bottom: 100px;
}

.btnList {
    padding-top: 30px;
    border-top: 1px solid #e5e6e8;
    display: flex;
    justify-content: space-between;
}

.reject {
    @include Pretendard(17px, 400, normal);
    letter-spacing: -0.2px;
    text-align: center;
    color: #151516;
    border-radius: 4px;
    width: 130px;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #151516;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #151516;
    margin-right: 20px;
    cursor: pointer;
    transition-duration: 0.2s;
}

.confirm {
    @include Pretendard(17px, 400, normal);
    letter-spacing: -0.2px;
    text-align: center;
    color: #151516;
    border-radius: 4px;
    width: 190px;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #151516;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #151516;
    cursor: pointer;
    transition-duration: 0.2s;
}

.confirm:hover, .reject:hover {
    box-shadow: 4px 4px 0px 0px #000000;
}