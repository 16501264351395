@import "../../../styles/utils.scss";

.title {
	@include Pretendard(42px, 700, normal);
	letter-spacing: -0.6px;
	color: #151516;
	margin-top: 80px;
}

.subtitleEng {
	@include Pretendard(24px, 600, normal);
	letter-spacing: -0.34px;
	color: #151516;
}

.subtitleKr {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-left: 8px;
	margin-bottom: 4px;
}

.subtitleDiv {
	display: flex;
	align-items: flex-end;
	margin-top: 69px;
}

.box {
	padding: 24px 20px;
	padding-bottom: 20px;
	border: 1px solid #43434a;
	margin-top: 25px;
	display: flex;
}

.boxElem {
	width: 235px;
}

.boxElemTitle {
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #63626b;
	margin-top: 8px;
}

.boxElemValue {
	margin-top: 24px;
	@include Pretendard(28px, 400, 1);
	color: #151516;
}
.boxElemSub {
	margin-top: 16px;
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #babbc0;
}
.line {
	width: 1px;
	background-color: #43434a;
	margin-right: 20px;
}

.ytmBox {
	margin-top: 27px;
	display: flex;
	width: 615px;
	justify-content: space-between;
	margin-bottom: 103px;
}

.ytmImg {
	width: 40px;
	height: 40px;
	object-fit:contain;
}

.ytmTop {
	display: flex;
	align-items: flex-end;
}

.ytmTitle {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #202429;
	margin-bottom: 6px;
	margin-left: 6px;
}

.ytmValue {
	@include Pretendard(28px, 500, 0.93);
	letter-spacing: -0.52px;
	color: #202429;
	margin-top: 12px;
	margin-left: 8px;
}