@import "../../../styles/utils.scss";

.wrapper {
	width: 1080px;
	margin: 0px auto;
	padding-bottom: 100px;
}

.subtitleEng {
	@include Pretendard(24px, 600, normal);
	letter-spacing: -0.34px;
	color: #151516;
}

.subtitleKr {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-left: 8px;
	margin-bottom: 4px;
}

.subtitleDiv {
	display: flex;
	align-items: flex-end;
	margin-top: 37px;
	margin-bottom: 28px;
}

.box {
	padding: 24px 28px;
	padding-bottom: 20px;
	border-radius: 4px;
	border: 1.5px solid #43434a;
	margin-top: 25px;
	display: flex;
	margin-bottom: 60px;
}

.boxElem {
	width: 277px;
}

.boxElemTitle {
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #63626b;
	margin-top: 10px;
	width: 242px;
}

.boxElemValue {
	margin-top: 16px;
	@include Pretendard(36px, 500, 1);
	line-height: 0.78;
	color: #151516;
	font-weight: 500 !important;
}

.boxElemValueBlue {
	margin-top: 16px;
	@include Pretendard(36px, 500, 1);
	line-height: 0.78;
	color: #3400ff;
}


.boxElemValueRed {
	margin-top: 16px;
	@include Pretendard(36px, 500, 1);
	line-height: 0.78;
	color: #ff424d;
}


.boxElemSub {
	margin-top: 16px;
	@include Pretendard(14px, normal, 1);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-bottom: 8px;
}
.line {
	width: 1.1px !important;
	background-color: #43434a;
	margin-right: 28px;
}

.royaltyBox {
	border: solid 1.5px #000;
	height: 378px;
	overflow: hidden;
	margin-bottom: 36px;
	transition-duration:0.3s;
}

.royaltyTitle {
	height: 104px;
	padding-left: 14px;
	padding-right: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.titleLeftWrapper {
	display: flex;
	align-items: center;
}

.titleRight {
	display: flex;
	align-items: center;
}

.royaltyTitleText {
	@include Pretendard(24px, 600, 0.83);
	letter-spacing: -0.34px;
	color: #151516;
	max-width: 398px;
	text-overflow: ellipsis;
}

.royaltyTerm {
	@include Pretendard(22px, 500, 0.91);
	margin-left: 32px;
	letter-spacing: -0.31px;
	color: #43434a;
}

.royaltyRoi {
	@include Pretendard(22px, 500, 0.91);
	margin-right: 32px;
	letter-spacing: -0.31px;
	color: #43434a;
}

.claimBox {
	display: flex;
	align-items: center;
	@include Pretendard(14px, 500, 1.14);
	letter-spacing: -0.26px;
	color: #181818;
	cursor: pointer;
}

.claimNumBox {
	width: 18px;
	height: 18px;
	margin-right: 10px;
	border: solid 1.5px #000;
	background-color: #ff424d;
	display: flex;
	align-items: center;
	justify-content: center;
	@include SpoqaHanSansNeo(10px, 500, normal);
	letter-spacing: -0.19px;
	color: #181818;
}

.arrows {
	width: 20px;
	height: 20px;
	object-fit: contain;
	margin-left: 20px;
	cursor: pointer;
}

.downRow {
	display: flex;
	margin: 24px 14px;
	margin-top: 0px;
}

.royaltyTable {
	margin-top: 35px;
	padding-left: 34px;
	padding-right: 46px;
	width: 440px;
	border-right: 1px solid black;
	border-left: 1px solid black;
}

.emRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.emTitle {
	@include Pretendard(18px, 500, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
}
.emRor {
	@include Pretendard(22px, 500, 0.91);
	letter-spacing: -0.31px;
	color: #151516;
	text-align: right;
	position: relative;
}

.row {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rowLast {
	margin-bottom: 20px;
	padding-bottom: 22px;
	border-bottom: 1px solid #e5e6e8;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #43434a;
}

.tableVal {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	text-align: right;
	color: #151516;
}

.tableValRed {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	text-align: right;
	color: #ff424d;
}

.annot {
	@include Pretendard(14px, 400, 1.43);
	letter-spacing: -0.2px;
	color: #151516;
	border: 0px;
}

.titleAnnot {
	@include Pretendard(22px, 500, 0.91);
	letter-spacing: -0.31px;
	color: #ff4f4f !important;
}

.btnList {
	display: flex;
	align-items: center;
	padding-left: 33px;
}

.activeBtn {
	width: 69px;
	height: 28px;
	border-radius: 2px;
	background-color: #151516;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #f6f6f8;
	margin-right: 20px;
}
.inactiveBtn {
	width: 73px;
	height: 28px;
	border-radius: 2px;
	border: solid 1px #747576;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #18191a;
}

.table2 {
	padding-right: 35px;
}

.bg {
	background-color: black;
	border-radius: 2px;
	width: fit-content;
	margin: 0px auto;
	margin-bottom: 32px;
}

.bgLast {
	background-color: black;
	border-radius: 2px;
	width: fit-content;
	margin: 0px auto;
}

.bgInv {
	background-color: #fff;
	border: 1.5px solid #000;
	border-radius: 3px;
	width: fit-content;
	margin: 0px auto;
	margin-bottom: 32px;
}

.table3 {
	padding-left: 33px;
	padding-right: 19px;
	padding-top: 66px;
}

.claimBtnInv:hover, .claimBtn:hover {
	transform: translate3d(-4px, -4px, 0);
}

.claimBtn {
	cursor: pointer;
	width: 208px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(17px, 500, 0.94);
	letter-spacing: -0.32px;
	color: #181818;
	border-radius: 2px;
	transition-duration: 0.3s;
	border: solid 1.5px #181818;
	background-color: white;
}

.claimBtnInv {
	cursor: pointer;
	width: 208px;
	overflow: hidden;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(17px, 500, 0.94);
	letter-spacing: -0.32px;
	color: #fff;
	border-radius: 2px;
	transition-duration: 0.3s;
	background-color: #000;
}


.boxElemTitleUnderlined {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #000;
	margin-top: 10px;
}

.btnBorder {
	margin-top: 15px;
	width: 208px;
	height: 65px;
	margin-left: 0px;
	border-radius: 2px;
	border: solid 1px #000;
}

.claimAllBtn {
	width: 208px;
	height: 65px;
	border-radius: 2px;
	background-color: #000;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(19px, 600, 0.84);
	letter-spacing: -0.36px;
	cursor: pointer;
	transition-duration: 0.3s;
}

.claimAllBtn img {
	width: 28px;
	height: 28px;
	object-fit: contain;
	margin-right: 8px;
}

.claimAllBtn:hover {
	transform: translate3d(-4px, -4px, 0);
}

.red {
	color: #ff424d;
}

.cardWrapper {
	background-color: #000;
	width: 245px;
	height: 385px;
}

.collectCard {
	width: 245px;
	height: 385px;
	border: solid 1px #13141a;
	background-color: #fff;
	position: relative;
	// cursor: pointer;
	transition-duration: 0.3s;
}

// .collectCard:hover {
// 	transform: translate3d(-6px, -6px, 0);
// }

.nameTag {
	position: absolute;
	top: 14px;
	left: 14px;
}

.coverWrapper {
	width: 243px;
	height: 243px;
}

.collectionCover {
	width: 245px;
	height: 245px;
	object-fit: cover;
}

.edition {
	@include Pretendard(14px, 300, 1.14);
	letter-spacing: -0.23px;
	color: #202429;
}

.project {
	margin-top: 8px;
	@include Pretendard(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
}

.projectLoop {
	margin-top: 8px;
	@include Pretendard(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);

}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.projectLoop div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.collectDetail {
	padding: 8px;
	padding-top: 12px;
}

.underPart {
	display: flex;
	margin-top: 32px;
}

.titleLeft {
	@include SpoqaHanSansNeo(13px, 400, 1.23);
	letter-spacing: -0.22px;
	color: #202429;
}

.titleRight {
	@include SpoqaHanSansNeo(13px, 400, 1.23);
	letter-spacing: -0.22px;
	text-align: right;
	color: #202429;
}

.minPrice {
	margin-top: 8px;
	@include SpoqaHanSansNeo(20px, 700, 1.3);
	letter-spacing: -0.38px;
	color: #202429;
}

.ror {
	margin-top: 10px;
	text-align: right;
	@include SpoqaHanSansNeo(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #202429;
}

.icon {
	width: 38px;
	height: 38px;
	object-fit: contain;
	margin-right: 14px;
	cursor: pointer;
}

.rowTitle {
	@include Pretendard(20px, bold, 1);
	letter-spacing: -0.29px;
	color: #151516;
	margin-top: 12px;
	margin-bottom: 32px;
}