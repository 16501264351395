@import "../../../styles/utils.scss";

.container {
	width: 708px;
	margin-bottom: 84px;
}

.titleContainer {
	display: flex;
	align-items: flex-end;
	border-bottom: 1px solid #e5e6e8;
}

.title {
	@include Pretendard(24px, 600, 1.67);
	letter-spacing: -0.45px;
	color: #151516;
	margin-right: 10px;
}

.subtitle {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-bottom: 8px;
}

.typeList {
	display: flex;
	align-items: center;
	margin-top: 24px;
	margin-bottom: 37px;
}

.typeTitle {
	margin-right: 28px;
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
}

.typeActive {
	margin-right: 20px;
	border: solid 1px #747576;
	padding: 3px 12px;
	background-color: #151516;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #f6f6f8;
	cursor: pointer;
}

.typeElem {
	margin-right: 20px;
	border: solid 1px #747576;
	padding: 3px 12px;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #18191a;
	cursor: pointer;
}

.slideWrapper {
	width: 708px;
	display: flex;
}

.leftSide {
	width: 338px;
	margin-right: 38px;
}

.rightSide {
	box-shadow: 6px 6px 0 0 #000;
	border: solid 1px #151516;
	background-color: #fff;
	width: 278px;
	padding: 24px 14px 25px;
}

.rightBox {
	@include Pretendard(14px, 600, normal);
	letter-spacing: -0.26px;
	color: #000;
	padding: 0px 6px;
	border: 1.5px solid black;
	padding-bottom: 1px;
	width: fit-content;
	margin-bottom: 20px;
}

.empty {
	height: 32px;
}

.rightValue {
	@include SpoqaHanSansNeo(36px, 400, normal);
	text-align: center;
	color: #151516;
}

.rightSub {
	@include Pretendard(12px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
}

.track {
	background-color: #e6e6e6;
	border-radius: 0px;
}

.ballonWrapper1 {
	position: relative;
	width: 127px;
	height: 85px;
	margin-left: 1px;
	margin-bottom: 2px;
}

.ballonWrapper2 {
	position: relative;
	width: 127px;
	height: 85px;
	margin-left: 1px;
	margin-left: 157px;
	margin-bottom: 2px;
}

.ballonWrapper3 {
	position: relative;
	width: 127px;
	height: 85px;
	margin-left: 226.5px;
	margin-bottom: 2px;
}

.ballon1, .ballon2, .ballon3 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 60px;
    border-radius: 4px;
    padding: 5px 13.5px;
    padding-bottom: 10px;
    border: solid 1px #000;

}

.ballon1:before {
    content: '';
    display: block;  
    position: absolute;
    top: 75px;
    left: 103px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: black;
}

.ballon1:after {
    content: '';
    display: block;  
    position: absolute;
    top: 73.5px;
    left: 103px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: white;
}

.ballon2:before {
    content: '';
    display: block;  
    position: absolute;
    top: 75px;
    left: 60px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: black;
}

.ballon2:after {
    content: '';
    display: block;  
    position: absolute;
    top: 73.5px;
    left: 60px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: white;
}

.ballon3:before {
    content: '';
    display: block;  
    position: absolute;
    top: 75px;
    left: 103px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: black;
}

.ballon3:after {
    content: '';
    display: block;  
    position: absolute;
    top: 73.5px;
    left: 103px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-color: white;
}

.upperImg {
	width: 26px;
	height: 26px;
	object-fit: contain;
	margin-right: 6px;
}

.upper {
	@include Pretendard(14px, 500, 1.43);
	letter-spacing: -0.2px;
	color: #202429;
	display: flex;
	align-items: center;
	justify-content: center;
}

.downer {
	margin-top: 10px;
	@include Pretendard(20px, 500, 1.3);
	letter-spacing: -0.38px;
	text-align: center;
	color: #202429;
}

.sub1 {
	margin-top: 16px;
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-bottom: 42px;
}

.tokens {
	@include Pretendard(20px, 600, 0.7);
	letter-spacing: -0.29px;
	color: #151516;
	margin-bottom: 20px;
}


.boughtLine {
	margin-top: 16px;
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.boughtLine img {
	 width: 22px;
	 height: 22px;
	 object-fit: contain;
}

.bought {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
}

.sub2 {
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #babbc0;
}