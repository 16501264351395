@import "../../styles/utils.scss";

.container {
  min-width: 1080px;
  a {
    text-decoration: none;
    color: #151516;
  }
  top:0;
  z-index: 10;

  // @include mobile {
  //   position: absolute;
  //   padding: 12px 20px;
  //   padding-top: 22px;
  //   width: calc(100% - 40px);
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   background-color: #fff;
  // }

    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 96px);
    padding: 0px 48px;
    height: 80px;

  .logo {

      margin-right: 37px;
      width: 144px;

    img {
      width: 100%;
    }
  }

  .menuContainer {
      display: flex;
      align-items: center;

    .menus {
      // @include mobile {
      //   display: none;
      // }
        @include SpoqaHanSansNeo(16px, 500, 0.75);
        letter-spacing: -0.27px;
        color: #151516;
        display: flex;

        .menuItem {
          margin-right: 48px;
          &:hover {
            color: #ff424d;
          }
        }
        .menuItem:first-child {
          margin-left: 62px;
        }

        .menuItem:last-child {
          margin-right: 0px;
        }
    }
  }

  .contents {
    display: flex;
    align-items: center;

    // @include mobile {
    //     display: none;
    //   }

    .wallet {
      @include SpoqaHanSansNeo(16px, 400, normal);
      color: $white;
      background-color: #ff4f4f;
      border: solid 1px #fff;
      padding: 10px 18px;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 14px;
        margin-left: 9px;
      }

      @include mobile {
        margin-right: 16px;
      }
    }

    // @include mobile {
    //   img {
    //     width: 24px;
    //     height: 24px;
    //   }
    // }

    .userContents {
        .profile {
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }

        .flag {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }

        .name {
          margin-right: 6px;
        }

        @include SpoqaHanSansNeo(14px, 500, 14px);
        letter-spacing: -0.23px;
        color: $gray-8;

        display: flex;
        align-items: center;
    }
  }
}

.fanPage {
    background-color: black;
    padding: 10px 25px;
    margin-left: 8px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    @include SpoqaHanSansNeo(16px, 400, normal);
}

.menuBox {
  display: none;
  position: absolute;
  z-index: 999;
  top: 21px;
  left: 0px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &.isActive {
    display: block;
  }

  &.isActiveLang {
    display: block;

    li {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }

      &:first-child {
        color: #ff424d;
      }
      &:last-child {
        margin-left: 24px;
      }
    }
  }

  justify-content: center;
  @include SpoqaHanSansNeo(14px, 500, 1.43);
  color: #151516;
  letter-spacing: -0.2px;
  padding: 18px 24px;
  list-style: none;

  li {
    margin-bottom: 12px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: #ff424d;
    }
  }
}

.login {
  width: 132px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #151516;
  color: #151516;
  @include Pretendard(16px, 400, 1);
  cursor: pointer;
}

.loginImg {
  width: 16px;
  height: 14px;
  margin-left: 10px;
}

.profile {
  position: relative;
  width: 173px;
  height: 37px;
}

.profileWrapper {
  width: 173px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include SpoqaHanSansNeo(14px, 500, normal);
  letter-spacing: -0.2px;
  color: #151516;
  cursor: pointer;
;}

.profileImg {
  width: 37px;
  height: 37px;
  margin-right: 10px;
}

.triangle {
  width: 12px;
  height: 9px;
  margin-left: 24px;
}

.profileMenu {
  width: 183px;
  height: 199px;
  padding: 5px 0px;
  border: solid 1px #000;
  background-color: #fff;
  margin-top: 8px;
  position: relative;

}

.profileBox {
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;

  margin-right: 24px;
}

.menuElem {
  padding: 12px 20px;
  @include Pretendard(14px, 500, 1.14);
  letter-spacing: -0.26px;
  color: #151516;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menuElem:hover {
  background-color: #f5f5f5;
}

.menuElemBottom:hover {
  background-color: #f5f5f5;
}

.menuElem img {
  width: 22px;
  height: 22px;
  margin-right: 12px;
  object-fit: contain;
}

.lineMenu {
  width: 184px;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 54px;
  left: 0;

}

.menuElemBottom {
  padding: 12px 20px;
  @include Pretendard(14px, 500, 1.14);
  letter-spacing: -0.26px;
  color: #151516;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.menuElemBottom img {
  width: 22px;
  height: 22px;
  margin-right: 12px;
  object-fit: contain;
}

.chevronIcon {
  &.isActive {
    transform: rotate(180deg);
  }
}

.mobileAlertBg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
}

.mobileAlertModal {
  width: fit-content;
  height: fit-content;
  padding: 10% 8%;
  @include Pretendard(20px, 500, 1.5);
  border-radius: 12px;
  background-color: #fff;
  text-align: center;
}

.mobileBtn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: #000;
  color: #fff !important;
  @include Pretendard(16px, 500, 1.5);
  letter-spacing: -0.26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.mobileHeaderContainer a {
  height: 28.88px;
}

.mobileHeaderContainer {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
}

.mobilelogo {
 width: 104px;
}

.plusBtn {
  width: 19px;
}