@import "../../../styles/utils.scss";

.wrapper {
	width: 1080px;
	padding-top: 140px;
	padding-bottom: 120px;
	margin: 0px auto;
}

.subTitle {
	@include Montserrat(14px, 400, 1.14);
	letter-spacing: -0.26px;
	text-align: center;
	color: #18191a;
	margin-bottom: 20px;
}

.title {
	@include Pretendard(42px, 800, 0.95);
	text-align: center;
	color: #151516;
	margin-bottom: 52px;
	align-self: center;
	position: relative;
}

.guideLine {
	display: flex;
	width: 100%;
	gap: 20px 32px;
	flex-wrap: wrap;
}

.guideTitle {
	@include Pretendard(20px, 500, normal);
	letter-spacing: -0.38px;
	color: #18191a;
	margin-top: 24px;
}

.guideWrapper {
	width: 338px;
	cursor: pointer;
}

.guideSub {
	@include Pretendard(16px, 500, 1.69);
	letter-spacing: -0.3px;
	color: #18191a;
	margin-top: 7px;
}

.guideImg {
	display: flex;
	height: 189px;
	justify-content: center;
	align-items: center;
	background-image: url("../../../assets/images/kleva.png");
	background-size: cover;
}


.more {
	position: absolute;
	@include Pretendard(16px, 400, 1);
	letter-spacing: -0.3px;
	color: #18191a;
	bottom: 8px;
	right: 0px;
	cursor: pointer;
	text-decoration: underline;

}