body {
  margin: 0;
}

#w3a-modal {
  z-index: 999;
}

body {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

* {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

strong {
  position: relative;
  font-weight: inherit;
}

strong::before {
  background-color: rgba(255, 66, 77, 0.83);
  content: "";
  position: absolute;
  font-weight: inherit;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: -1.5px;
  z-index: -1;
  transform: rotate(0deg);
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*:focus {
  outline: none !important;
}