@import "../../../styles/utils.scss";

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	@include Pretendard(42px, 700, normal);
	letter-spacing: -0.6px;
	color: #151516;
	margin-bottom: 9px;
}

.wrapper {
	position:absolute;
	display:flex;
	top:-64px;
	bottom: 0;
	left:0;
	right:0;
	pointer-events:none;
}

.container {
	position: sticky;
	z-index:1;
	margin:auto -0.5em 3em auto;
	bottom: calc(100vh - 530px);
	padding: 5px 7px;
	pointer-events:initial;
}

.box {
	border: solid 1px #000;
	width: 307px;
	padding: 16px;
	margin-top: -1px;
}

.titleWrapper {
	display: flex;
	align-item: center;
	justify-content: space-between;
	cursor: pointer;
}

.typeTitle {
	@include Pretendard(18px, 700, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
}

.arrow {
	width: 14px;
	height: 8px;
	margin-left: 8px;
}

.infoElemTop {
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.infoElem {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.infoTitle {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #43434a;
}
.infoData {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #151516;
}

.inactive{
	height: 0px;
	overflow: hidden;
}
.active {}

.bar {
	margin-top: 20px;
	background-color: #e6e6e6;
	height: 10px;
}

.black {
	background-color: black;
	height: 10px;
}

.btn {
	margin-top: 18px;
	@include Pretendard(17px, 400, normal);
	color: #151516;
	width: 301px;
	border-radius: 2px;
	border: 1px black solid;
	text-align: center;
	padding: 10px 0px;
	box-shadow: 3px 4px 0px 1px #000000;
	margin-bottom: 4px;
	cursor: pointer;
}
.btnLogin {
	margin-top: 18px;
	@include Pretendard(17px, 400, normal);
	color: #fff;
	width: 301px;
	border-radius: 2px;
	border: 1px black solid;
	text-align: center;
	padding: 10px 0px;
	background-color: #000;
	margin-bottom: 4px;
	cursor: pointer;
}

.btnGray {
	margin-top: 18px;
	@include Pretendard(17px, 400, normal);
	color: #151516;
	width: 301px;
	border-radius: 2px;
	border: 1px black solid;
	text-align: center;
	padding: 10px 0px;
	box-shadow: 3px 4px 0px 1px #000000;
	margin-bottom: 4px;
	cursor: pointer;
	background-color: #a5a5a5;
}

.mintend {
	@include Pretendard(12px, 500, 1.67);
	letter-spacing: -0.17px;
	color: #ff424d;
}

.opensea {
	margin-bottom: -4px;
	height: 24px;
	object-fit: contain;
}