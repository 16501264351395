@import "../../styles/utils.scss";


canvas {
	z-index: 0 !important;
}

wave {
	z-index: 0 !important;
}
.btn {
	width: 80px;
	height: 80px;
	background-color: #18191a;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.btn img {
	height: 28px;
}

.wrapper {
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;
}

.charTitle {
	@include SpoqaHanSansNeo(30px, 700, 1.4);
	letter-spacing: -0.6px;
	color: #151516;
	margin-left: 28px;
}
.timer {
	position: absolute;
	top: 164px;
	left: 14px;
	z-index: 0;
	padding: 6px 12px;
	background-color: white;
	border-radius: 4px;
	@include SpoqaHanSansNeo(20px, 500, 1.2);
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.playerWrapper {
	position: relative;
}
