@import "../../../styles/utils.scss";

.container {
	width: 708px;
}

.titleContainer {
	display: flex;
	align-items: flex-end;
	border-bottom: 1px solid #e5e6e8;
}

.title {
	@include Pretendard(24px, 600, 1.67);
	letter-spacing: -0.45px;
	color: #151516;
	margin-right: 10px;
}

.subtitle {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-bottom: 8px;
}

.typeList {
	display: flex;
	align-items: center;
	margin-top: 24px;
	margin-bottom: 37px;
}

.typeTitle {
	margin-right: 28px;
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
}

.typeActive {
	margin-right: 20px;
	border: solid 1px #747576;
	padding: 3px 12px;
	background-color: #151516;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #f6f6f8;
	cursor: pointer;
}

.typeElem {
	margin-right: 20px;
	border: solid 1px #747576;
	padding: 3px 12px;
	@include Pretendard(14px, 500, normal);
	letter-spacing: -0.26px;
	color: #18191a;
	cursor: pointer;
}

.detailWrapper {
	border: 1px solid #13141a;
	display: flex;
	margin-bottom: 113px;
}


.leftSide {
	border-right: 1px solid #13141a;
}

.cover {
	 width: 243px;
	 height: 243px;
	 background-size: cover;
	 position: relative;
}

.cover video {
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 40px;
}

.tag {
	position: absolute;
	top: 13px;
	left: 13px;
}

.artists {
	margin-top: 12px;
	margin-left: 8px;
	width: 228px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	@include SpoqaHanSansNeo(14px, 300, 1.14);
	letter-spacing: -0.23px;
	color: #202429;
}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.detailTitle {
	margin-top: 4px;
	width: 228px;
	@include SpoqaHanSansNeo(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);

}

.detailTitle div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.detailTitleNoLoop {
	width: 228px;
	margin-top: 4px;
	@include SpoqaHanSansNeo(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
}

.bottomWrapper {
	margin-top: 26px;
	margin-bottom: 8px;
	margin-left: 8px;
	width: 228px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bottomTitle {
	@include SpoqaHanSansNeo(13px, 400, 1.23);
	letter-spacing: -0.22px;
	color: #202429;
	margin-bottom: 8px;
}

.bottomRight {
	text-align: right;
}

.bottomPrice {
	@include SpoqaHanSansNeo(20px, 700, 1.3);
	letter-spacing: -0.38px;
	color: #202429;
}
.bottomYtd {
	@include SpoqaHanSansNeo(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #202429;
}

.rightSide {
	padding: 24px 32px;
	padding-bottom: 8px;
	width: 400px;
}

.mintTitle {
	@include Pretendard(18px, 600, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
	margin-bottom: 22px;
}

.textWrapper {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.textTitle {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #43434a;
}

.textElem {
	@include Pretendard(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #151516;
}


.holderTitle {
	@include Pretendard(18px, 600, 1.43);
	letter-spacing: -0.26px;
	color: #151516;
	margin-bottom: 20px;
	margin-top: 37px;
}

.benefitWrapper {
	background-color: #13141a;
}
.benefitElem {
	width: 116px;
  	height: 116px;
	border: 1px solid #13141a;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	transition-duration: 0.3s;
}

.benefitElem:hover {
	transform: translate3d(-2px, -2px, 0);
}

.benefitImg {
	width: 60px;
	height: 40px;
	object-fit:contain;
	margin-bottom: 10px;
}
.benefitTitle {
	text-align: center;
	@include Pretendard(11px, 500, 1.82);
	letter-spacing: -0.16px;
	color: #202429;
	margin-bottom: 2px;
}

.benefitSub {
	text-align: center;
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #babbc0;
}

.modalBg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(21, 21, 22, 0.8);
}

.modal {
	width: 475px;
	background-color: white;
	border: 1px solid #181818;
	padding: 19px 25px;
	margin: 183px auto;
	position: relative;
}

.modalPayment {
	width: 340px;
	background-color: white;
	border: 1px solid #181818;
	padding: 19px 25px;
	margin: 183px auto;
	position: relative;
}

.modalTitlePayment {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	text-align: center;
	color: #202429;
	margin-bottom: 39px;
}

.modalTitle {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	text-align: center;
	color: #202429;
	margin-bottom: 80px;
}

.eventImg {
	width: 110px;
	object-fit: contain;
	margin: 0px auto;
	display: block;
	margin-bottom: 12px;
}

.eventName {
	@include Pretendard(20px, 500, 1);
	letter-spacing: -0.29px;
	text-align: center;
	color: #202429;
	margin-bottom: 8px;
}

.eventAmt {
	@include Pretendard(14px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 34px;
}

.eventDetailTitle {
	@include Pretendard(18px, 600, 1.11);
	letter-spacing: -0.26px;
	color: #151516;
	margin-bottom: 12px;
}

.eventDetail {
	@include Pretendard(14px, 500, 1.5);
	letter-spacing: -0.2px;
	color: #151516;
	margin-bottom: 36px;
}

.doneBtn {
	cursor: pointer;
	width: calc(100% - 20px);
	padding: 10px;
	@include Pretendard(17px, 400, normal);
	text-align: center;
	color: #151516;
	border: 1px black solid;
	box-shadow: 3px 4px 0px 1px #000000;
}

.close {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 21px;
	right: 25px;
}

.close img {
	width: 20px;
	height: 20px;
	object-fit: contain;
}

.modalTypeBox {
	padding: 4px 6px;
	@include Pretendard(16px, 600, normal);
	letter-spacing: -0.3px;
	text-align: center;
	color: #000;
	border: 1.5px black solid;
	width: fit-content;
	margin-left: 21px;
	margin-bottom: 20px;
}

.modalPriceBox {
	@include SpoqaHanSansNeo(36px, 400, normal);
	text-align: center;
	color: #151516;
	margin-bottom: 2px;
}

.modalPerBox {
	@include Pretendard(12px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 71px;
}

.modalLine {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.balanceLine {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineLast {
	padding-bottom: 12px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineTitle {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
}

.amtBox {
	display: flex;
	align-items:center;
	justify-content: center;
}

.blackBtn {
	background-color: #181818;
	width: 29px;
	height: 29px;
	color: #f6f6f8;
	display: flex;
	align-items:center;
	justify-content: center;
	cursor: pointer;
}

.amt {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	text-align: center;
	color: #404eed;
	width: 50px;
	height: 27px;
	border: 0px;
	display: flex;
	align-items:center;
	justify-content: center;
	border-top: solid 1px #181818;
	border-bottom: solid 1px #181818;
}

.modalLineValue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #43434a;
}

.modalLineValueBlue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #404eed;
}

.balanceBox {
	border: solid 1px #181818;
	padding: 6px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 222px;
}

.balanceLeft {
	display: flex;
	align-items: center;
}

.balanceImg {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 8px;
}

.balanceTicker {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.balanceAmt {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.buyBtn {
	@include Pretendard(17px, 400, normal);
	color: #151516;
	border-radius: 4px;
	border: solid 1px #151516;
	text-align: center;
	padding: 10px 0px;
	box-shadow: 4px 4px 0px 0px #000000;
	margin-bottom: 4px;
	cursor: pointer;
}

.holderSub {
	@include Pretendard(12px, 400, normal);
	letter-spacing: -0.17px;
	color: #babbc0;
	padding-left: 8px;
}

.inline {
	display: flex;
	justify-content: space-between;
}

.buttons {
	margin-bottom: 20px;
	margin-top: 37px;
	display: flex;
	align-items: center;
}

.rbtn {
	margin-top: -2px;;
	cursor: pointer;
	transform: rotate(180deg);
	margin-right: 6px;
}
.lbtn {
	cursor: pointer;
}