@import "../../styles/utils.scss";

.container {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background-color: rgba(21, 21, 22, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrapper {
	padding: 20px 25px;
	width: 340px;
	background-color: white;
	border: 1px solid #181818;
	position: relative;
}

.x {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
}

.title {
	@include Pretendard(22px, 500, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
	text-align: center;
	margin-bottom: 39px;
}

.addr {
	cursor: pointer;
	@include SpoqaHanSansNeo(14px, 500, normal);
	letter-spacing: -0.2px;
	color: #fff;
	padding: 8px 14px 6px;
	background-color: #151516;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	margin: 0px auto;
	border-radius: 2px;
	margin-bottom: 43px;
}

.addr img {
	width: 20px;
	height: 20px;
	object-fit: contain;
	margin-left: 6px;
}

.usdcBal {
	@include SpoqaHanSansNeo(32px, 400, normal);
	color: #151516;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	margin: 0px auto;
}

.usdcBal img {
	width: 34px;
	height: 34px;
	object-fit:contain;
	margin-right: 8px;
}

.sub1 {
	@include Pretendard(12px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 48px;
}

.sub2 {
	@include Pretendard(12px, 500, 1.17);
	letter-spacing: -0.17px;
	text-align: center;
	color: #babbc0;
	margin-bottom: 38px;
}

.row, .rowLast, .rowAsset {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
}

.rowAsset {
	margin-bottom: 30px;
}

.assetBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 10px;
	border: solid 1px #181818;
	cursor: pointer;
	width: 222px;
}

.leftSide {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
	display: flex;
	align-items: center;
}

.leftSide img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 8px;
}

.rightSide {
	display: flex;
	align-items: center;
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
	text-align: right;
}

.rightSide img {
	width: 14px;
	height: 14px;
	object-fit: contain;
	margin-left: 8px;
}

.assetRow1, .assetRow2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 222px;
	padding: 6px 10px;
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
	cursor: pointer;
}
.assetRow1 {
	
	border-bottom: 1px solid #181818;
}

.assetLeft {
	display: flex;
	align-items: center;

}

.assetLeft img {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 8px;
}

.dropdown {
	height: 65px;
	width: 242px;
	border: 1px solid #181818;
	position: absolute;
	top: 566px;
	right: 25px;
	overflow: hidden;
	transition-duration: 0.3s;
	background-color: white;
}

.rowLast {
	padding-bottom: 12px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 30px;
}

.header {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
}

.elem, .underline, .blueVal, .redVal, .blueValNoLine {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
	text-align: right;
	color: #43434a;
}

.blueValNoLine {
	color: #404eed;
}

.blueVal {
	color: #404eed;
	text-decoration: underline;
	cursor: pointer;
}
.redVal {
	color: #ff424d;
	text-decoration: underline;
	cursor: pointer;
}
.underline {
	text-decoration: underline;
	cursor: pointer;
}

.send {
	cursor: pointer;
	 width: 340px;
	 height: 42px;
	 border-radius: 3px;
	 box-shadow: 4px 4px 0 0 #000;
	 border: solid 1px #151516;
	 @include Pretendard(17px, 400, normal);
	 color: #151516;
	 display: flex;
	 align-items: center;
	 justify-content: center;
}

.prev {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 25px;
}

.prev img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}


.balanceLine {
	margin-bottom: 41px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineLast {
	padding-bottom: 12px;
	border-bottom: 1px solid #e5e6e8;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalLineTitle {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
}

.amtBox {
	display: flex;
	align-items:center;
	justify-content: center;
}

.blackBtn {
	background-color: #181818;
	width: 29px;
	height: 29px;
	color: #f6f6f8;
	display: flex;
	align-items:center;
	justify-content: center;
	cursor: pointer;
}

.amt {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	text-align: center;
	color: #404eed;
	width: 50px;
	border: 0px;
	display: flex;
	align-items:center;
	justify-content: flex-end;
}

.modalLineValue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #43434a;
}

.modalLineValueBlue {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #404eed;
}

.balanceBox {
	border: solid 1px #181818;
	padding: 6px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 222px;
}

.balanceLeft {
	display: flex;
	align-items: center;
}

.balanceImg {
	width: 17px;
	height: 17px;
	object-fit: contain;
	margin-right: 8px;
}

.balanceTicker {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.balanceAmt {
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #181818;
}

.noBal2 {
    @include Pretendard(12px, 500, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    margin-top: -38px;
    margin-bottom: 100px;
}

.insufficent {
    color: #ff424d;
}


.charge {
    text-decoration: underline !important;
    cursor: pointer;
}

.input {
	padding: 8px 52px 8px 52px;
	border-radius: 3px;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 400, 1);
	color: #000;
	width: calc(100% - 104px);
	height: 36px;
	resize: none; 
	margin-bottom: 41px;
}

.line {
	width: 340px;
	height: 1px;
	margin: 0px 0 30px;
	background-color: #e5e6e8;
}

.assetContainer {
	margin-bottom: 110px;
}

.btnList {
    padding-top: 30px;
    border-top: 1px solid #e5e6e8;
    display: flex;
    justify-content: space-between;
}

.btnList2 {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.reject {
    @include Pretendard(17px, 400, normal);
    letter-spacing: -0.2px;
    text-align: center;
    color: #151516;
    border-radius: 4px;
    width: 130px;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #151516;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #151516;
    margin-right: 20px;
    cursor: pointer;
    transition-duration: 0.2s;
}

.confirm {
    @include Pretendard(17px, 400, normal);
    letter-spacing: -0.2px;
    text-align: center;
    color: #151516;
    border-radius: 4px;
    width: 190px;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #151516;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #151516;
    cursor: pointer;
    transition-duration: 0.2s;
}

.confirm:hover, .reject:hover {
    box-shadow: 4px 4px 0px 0px #000000;
}


.minting {
    width: 146px;
    height: 32px;
    margin: 0px auto;
    background-color: #000000;
    @include Pretendard(14px, 500, normal);
    letter-spacing: -0.2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 41px;
}

.profilePic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.address {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tolist {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 51px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 36px;
    color: #151516;
    @include Pretendard(12px, 500, normal);
}

.left {
    width: 17px;
    height: 17px;
    object-fit: contain;
}

.col {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tableTitle {
    @include Pretendard(12px, 600, normal);
    letter-spacing: -0.2px;
    color: #151516;
}

.tableElem {
    @include Pretendard(12px, 600, normal);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.maxgas {
    @include Pretendard(12px, 400, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 10px;
}

.noBal {
    @include Pretendard(12px, 500, 1.67);
    letter-spacing: -0.17px;
    text-align: right;
    color: #181818;
    margin-top: -8px;
    margin-bottom: 10px;
}

.assetTitle {
	@include Pretendard(14px, 600, 1);
	letter-spacing: -0.2px;
	color: #151516;
	margin-bottom: 14px;
}

.assetWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
}

.assetImg {
	width: 101px;
	height: 101px;
	object-fit: cover;
}

.assetType {
	@include SpoqaHanSansNeo(14px, 600, 1.71);
	letter-spacing: -0.26px;
	text-align: right;
	color: #202429;
}

.assetDetail {
	@include SpoqaHanSansNeo(12px, 400, 1.5);
	letter-spacing: -0.23px;
	text-align: right;
	color: #202429;
}