@import "../../../styles/utils.scss";

.container {
	width: 1080px;
	margin: 0px auto;
	margin-top: 193px;
	height: 468px;
	margin-bottom: 48px;
}

.bannerContainer {
	width: 1080px;
	display: flex;
}

.elementContainer {
	width: 1080px;
	height: 360px;
	display: flex;
	overflow: hidden;
	position: relative;
}

.leftSide {
	width: 430px;
	margin-right: 65px;
}

.title {
	width: 100%;
	margin-top: 12px;
	@include Pretendard(50px, 700, normal);
	letter-spacing: -0.91px;
	color: #000;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.subtext {
	width: 100%;
	margin-top: 20px;
	@include Pretendard(18px, 500, 1.5);
	letter-spacing: -0.26px;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.titleHidden {
	width: 100%;
	margin-top: 12px;
	@include Pretendard(50px, 700, normal);
	letter-spacing: -0.91px;
	color: #000;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.subtextHidden {
	width: 100%;
	margin-top: 20px;
	@include Pretendard(18px, 500, 1.5);
	letter-spacing: -0.26px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}


.btnWrapper {
	display: flex;
	position: absolute;
	bottom: 33px;

}

.detailBtn {
	width: 122px;
	height: 49px;
	background-color: #151516;
	@include Pretendard(17px, 500, 1);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 36px;
	opacity: 1;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.detailBtnHidden {
	width: 122px;
	height: 49px;
	background-color: #151516;
	@include Pretendard(17px, 500, 1);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 36px;
	opacity: 0;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.investBtn {
	width: 126px;
	height: 49px;
	border-radius: 4px;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 500, 1);
	color: #151516;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 1;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.investBtnHidden {
	width: 126px;
	height: 49px;
	border-radius: 4px;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 500, 1);
	color: #151516;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.rightSide {
	width: 585px;
	position: relative;
}

.playbtn {
	z-index: 2;
	position: absolute;
	width: 64px;
	top: 148px;
	left: 261px;
	cursor: pointer;
}

.objectFit {
	object-fit: 100%;
	transform: translateY(0);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.objectFitHidden {
	object-fit: 100%;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}



.paginationContainer {
	display: flex;
	margin-top: 30px;
	width: 100%;
	grid-gap: 16px
}

.pagination {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: none;
    border: 1px solid #000;
	@include Pretendard(16px, 700, 1);
    border-radius: 48px;
    color: #000;
    text-align: center;
}

.paginationActive {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: none;
    border: 1px solid #000;
	@include Pretendard(16px, 700, 1);
    border-radius: 48px;
    color: #000;
    text-align: center;
	background-color: #ff424d;
}

.pagination:hover {
	background-color: #ff424b49;
}