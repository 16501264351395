@import "../../styles/utils.scss";

.top {
	position: relative;
}

.label {
	margin-top: 40px;
	@include SpoqaHanSansNeo(12px, 600, 1.67);
	letter-spacing: -0.2px;
	color: #18191a;
	margin-bottom: 12px;
}

input:focus { outline: none; }

.inputs::placeholder {
	color: #babbc0;
}

.inputs {
	@include SpoqaHanSansNeo(16px, 500, 1.5);
	letter-spacing: -0.4px;
	border-bottom: 1px #e5e6e8 solid;
}

.checked {
	width: 24px;
	height: 24px;
	margin-left: 10px;
}

.refresh {
	width: 20px;
	height: 20px;
	margin-bottom: 2px;
	cursor: pointer;
}

.btns {
	position: relative;
	position: absolute;
	top: 34px;
	right: 0px;
}

.blockInput::placeholder {
	color: #babbc0;
}


.blockInput {
	@include SpoqaHanSansNeo(16px, 500, 1.5);
	letter-spacing: -0.4px;
	border-radius: 4px;
	border: solid 1px #e5e6e8;
	padding: 10px 7px 10px 8px;
	@include desktop {
		margin-right: 12px;
	}
	@include mobile {
		margin-top: 8px;
	}
}

.blockInput:first-child {
	@include mobile {
		margin-top: 0px;
	}
}

.blockInput:last-child {
	margin-right: 0px;
}

.blocks {
	display: flex;
	@include mobile {
		flex-wrap: wrap;
	}
}

.errorLabel {
	@include SpoqaHanSansNeo(12px, 500, 1.67);
	margin-top: 4px;
	letter-spacing: -0.2px;
	color: #ff5300;
	position: absolute;
}