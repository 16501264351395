@import "../../../styles/utils.scss";

.wrapper {
	width: 1080px;
	margin: 0px auto;
	padding-bottom: 100px;
}

.subtitleEng {
	@include Pretendard(24px, 600, normal);
	letter-spacing: -0.34px;
	color: #151516;
}

.subtitleKr {
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.2px;
	color: #babbc0;
	margin-left: 8px;
	margin-bottom: 4px;
}

.subtitleDiv {
	display: flex;
	align-items: flex-end;
	margin-top: 37px;
	margin-bottom: 28px;
}



.collectionTypeWrapper {
	display: flex;
	align-items: flex-start;
	margin-top: 22px;
	margin-bottom: 24px;
}

.collectionTypeWrapperBottom {
	display: flex;
	align-items: flex-start;
	margin-top: 22px;
	margin-bottom: 71px;
}

.filterTitle {
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
	margin-top: 2px;
	margin-right: 36px;
}

.filterTitleSec {
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
	margin-top: 2px;
	margin-right: 52px;
}

.filterList {
	display: flex;
	gap: 12px 20px;
	flex-wrap: wrap;
	width: 916px;
}

.filterBtn {
	padding: 4px 12px;
	border-radius: 2px;
	border: solid 1px #747576;
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.26px;
	color: #18191a;
	cursor: pointer;
}

.filterBtnActive {
	padding: 4px 12px;
	border-radius: 2px;
	border: solid 1px #747576;
	@include Pretendard(14px, 400, normal);
	background-color: #151516;
	color: #f6f6f8;
	letter-spacing: -0.26px;
	cursor: pointer;
}

.filterBtn:hover {
	background-color: #18191a;
	color: white;
}

.table {
	border-collapse: collapse;
}

.header {
	border-bottom: solid 1px #13141a;
	@include Pretendard(16px, 600, 1.25);
}

.header th {
	padding-bottom: 15px;
	table-layout: fixed;
}

.flexleft {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flexright {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex1 {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex2 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 2;
}

.table td {
	@include Pretendard(14px, 400, 1.43);
	letter-spacing: -0.2px;
	text-align: center;
	color: #151516;
	padding: 20px 0px;
}

.elemTitle {
	@include Pretendard(14px, 500, 1);
	letter-spacing: -0.2px;
	color: #43434a;
	text-align: center;
	vertical-align: middle;
}

.elemTitle div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.elemTitle img {
	width: 18px;
	height: 18px;
	object-fit: contain;
	margin-top: 2px;
	margin-right: 5px;
}

.elemItem img {
	width: 76px;
	height: 76px;
	border-radius: 4px;
	object-fit: cover;
}

.elemItemWrapper {
	display: flex;
	width: 287px;
	align-items: center;
	justify-content: flex-start;
}

.elemItemL {
	margin-left: 10px;
	word-break: break-all;
}

.elemItemLT {
	text-align: left;
	@include Pretendard(14px, 500, 1.43);
	letter-spacing: -0.2px;
	color: #151516;
	margin-bottom: 5px;
}
.elemItemLB {
	text-align: left;
	@include Pretendard(12px, 400, 1.67);
	letter-spacing: -0.17px;
	color: #151516;
}

.priceT {
	width: 100px;
	text-align: right;
	@include Pretendard(14px, 600, 1.43);
	letter-spacing: -0.2px;
	color: #ff424d;
}

.priceB {
	width: 100px;
	text-align: right;
	@include Pretendard(12px, 600, 1.67);
	letter-spacing: -0.17px;
	color: #c8c8d2;
}

table .tx {
	text-align: right;
	@include Pretendard(14px, 500, 1.43);
	letter-spacing: -0.2px;
	color: #1109c4;
	cursor: pointer;
	text-decoration: underline;
}

.moreBtn {
	cursor: pointer;
	width: fit-content;
	margin: 0px auto;
	margin-top: 70px;
	margin-bottom: 20px;
	padding: 12px 24px;
	border-radius: 4px;
	box-shadow: 4px 4px 0 0 #000;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 400, normal);
	color: #151516;
}

.blank {
	height: 20px;
}