@import "../../../styles/utils.scss";

.cardWrapper {
	background-color: #000;
	width: 245px;
	height: 385px;
}

.topCollectionWrapper {
	width: 1080px;
	margin: 0px auto;
}

.titleWrapper {
	border-bottom: 2px solid #e5e6e8;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	margin-bottom: 16px;
	@include Pretendard(42px, 700, 0.95);
	letter-spacing: -0.79px;
	color: #151516;
}

.allCollections {
	display: flex;
	align-items: center;
	justify-content: center;
	@include Pretendard(16px, 500, 1);
	letter-spacing: -0.3px;
	color: #151516;
	cursor: pointer;
}

.allCollections img {
	margin-left: 6px;
	margin-right: 6px;
	margin-top: -1px;
	transition-duration: 1s;
}

.allCollections:hover img {
	margin-left: 12px;
	margin-right: 0px;
}

.collectionTypeWrapper {
	display: flex;
	align-items: flex-start;
	margin-top: 22px;
	margin-bottom: 24px;
}

.collectionTypeWrapperBottom {
	display: flex;
	align-items: flex-start;
	margin-top: 22px;
	margin-bottom: 71px;
}

.filterTitle {
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
	margin-top: 2px;
	margin-right: 36px;
}

.filterTitleSec {
	@include Pretendard(17px, 400, normal);
	letter-spacing: -0.32px;
	color: #151516;
	margin-top: 2px;
	margin-right: 52px;
}

.filterList {
	display: flex;
	gap: 12px 20px;
	flex-wrap: wrap;
	width: 916px;
}

.filterBtn {
	padding: 4px 12px;
	border-radius: 2px;
	border: solid 1px #747576;
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.26px;
	color: #18191a;
	cursor: pointer;
}

.filterBtnActive {
	padding: 4px 12px;
	border-radius: 2px;
	border: solid 1px #747576;
	@include Pretendard(14px, 400, normal);
	background-color: #151516;
	color: #f6f6f8;
	letter-spacing: -0.26px;
	cursor: pointer;
}

.filterBtn:hover {
	background-color: #18191a;
	color: white;
}

.collectCard {
	width: 245px;
	height: 385px;
	border: solid 1px #13141a;
	background-color: #fff;
	position: relative;
	cursor: pointer;
	transition-duration: 0.3s;
}

.collectCard:hover {
	transform: translate3d(-6px, -6px, 0);
}

.nameTag {
	position: absolute;
	top: 14px;
	left: 14px;
}

.coverWrapper {
	width: 243px;
	height: 243px;
}

.collectionCover {
	width: 245px;
	height: 245px;
	object-fit: cover;
}

.edition {
	@include Pretendard(14px, 300, 1.14);
	letter-spacing: -0.23px;
	color: #202429;
}

.project {
	margin-top: 8px;
	@include Pretendard(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
}

.projectLoop {
	margin-top: 8px;
	@include Pretendard(22px, 700, 1.09);
	letter-spacing: -0.41px;
	color: #202429;
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);
	mask-image: linear-gradient(90deg, #262626, #262626 0, transparent 0, #262626 20px, #262626 calc(100% - 20px), transparent);

}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.projectLoop div {
	-webkit-animation: loop 8s infinite linear;
	animation: loop 8s infinite linear;
}

.collectDetail {
	padding: 8px;
	padding-top: 12px;
}

.underPart {
	display: flex;
	margin-top: 25px;
	justify-content: space-between;
}

.titleLeft {
	@include SpoqaHanSansNeo(13px, 400, 1.23);
	letter-spacing: -0.22px;
	color: #202429;
}

.titleRight {
	@include SpoqaHanSansNeo(13px, 400, 1.23);
	letter-spacing: -0.22px;
	text-align: right;
	color: #202429;
}

.minPrice {
	margin-top: 8px;
	@include SpoqaHanSansNeo(20px, 700, 1.3);
	letter-spacing: -0.38px;
	color: #202429;
}

.ror {
	margin-top: 10px;
	text-align: right;
	@include SpoqaHanSansNeo(16px, 500, 1.25);
	letter-spacing: -0.23px;
	color: #202429;
}

.collectionsWrapper {
	display: flex;
	gap: 45px 30px;
	flex-wrap: wrap;
}

.moreBtn {
	cursor: pointer;
	width: fit-content;
	margin: 0px auto;
	margin-top: 70px;
	margin-bottom: 140px;
	padding: 12px 24px;
	border-radius: 4px;
	box-shadow: 4px 4px 0 0 #000;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 400, normal);
	color: #151516;
}

.blank {
	height: 150px;
}