@import "../../../styles/utils.scss";

.lineWrapper {
	width: 100%;
	border-bottom: solid 1px #000;
}

.topWrapper {
	width: 1080px;
	margin: 0px auto;
	display: flex;
	margin-bottom: 100px;
}

.leftTop {
	border-right: solid 1px #000;
	@include Pretendard(16px, 500, normal);
	letter-spacing: -0.3px;
	color: #18191a;
	flex: 1;
	padding: 40px 0px;
}

.rightTop {
	@include Pretendard(20px, 500, normal);
	letter-spacing: -0.3px;
	color: #18191a;
	flex: 1;
	display: flex;
	align-items: center;
	padding: 38px 0px;
	letter-spacing: -0.38px;
	color: #18191a;
}

.rightTop div {
	margin-left: 24px;
}

.rightTop img {
	width: 24px;
	height: 24px;
	object-fit: contain;
	margin-left: 32px;
	cursor: pointer;
}

.downWrapper {
	width: 1080px;
	margin: 0px auto;
	display: flex;
}

.leftDown {
	border-right: solid 1px #000;
	@include Pretendard(14px, 400, normal);
	letter-spacing: -0.26px;
	color: #18191a;
	flex: 1;
	padding: 28px 0px;
	display: flex;
	justify-content: space-between;
}

.menuList {
	width: 160px;
}

.menuTitle {
	@include Pretendard(16px, 500, normal);
	letter-spacing: -0.3px;
	margin-bottom: 16px;
}

.menuElem {
	margin-bottom: 8px;
	cursor: pointer;
}

.menuElem:hover {
	color: #ff424d;
}

.rightDown {
	flex: 1;
}

.invert {
	background-color: #fff;
	filter: invert(100%);
	-webkit-filter: invert(100%);
}