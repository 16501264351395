@import "../../../styles/utils.scss";

.wrapper {
	padding-top: 129px;
	padding-bottom: 120px;
	background-color: #181818;
	overflow: hidden;
}

.container {
	width: 1080px;
	margin: 0px auto;
}

.titleWrapper {
	display: flex;
	justify-content: space-between;
}

.leftSide {
	width: 523px;
}

.rightSide {
	width: 524px;
	text-align: right;
}

.title {
	@include Pretendard(36px, 700, 1.5);
	color: #f6f6f8;
}

.subtext {
	margin-top: 36px;
	@include Pretendard(22px, 400, 1.55);
	color: #f6f6f8;
}

.playBtn {
	cursor: pointer;
	margin-right: 12px;
}

.infoWrapper {
	display: flex;
	align-items: center;
	margin-top: 30px;
	@include Pretendard(16px, 400, 1);
	letter-spacing: -0.3px;
	color: #f6f6f8;
	cursor: pointer;
}

.supporterTitle {
	display: flex;
	align-items: flex-end;
	margin-top: 88px;
}

.sTitle {
	@include Pretendard(36px, 800, 1.11);
	letter-spacing: -0.67px;
	color: #f6f6f8;
	margin-right: 16px;
}

.sSub {
	@include Pretendard(16px, 400, 1.38);
	letter-spacing: -0.3px;
	color: #f6f6f8;
}

.cardWrapper {
	width: 217px;
	height: 287px;
	padding: 14px;
	border: solid 1px #f6f6f8;
	background-color: #181818;
	cursor: pointer;
	position: relative;
}

.cardWrapper:hover {
	background-color: #fff;
}

.cardTitle {
	@include Pretendard(20px, 700, 1.2);
	letter-spacing: -0.38px;
	color: #f6f6f8;
	margin-top: 27px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.cardWrapper:hover .cardTitle {
	color: #181818;
}

.cardContent {
	@include Pretendard(15px, 500, 1.6);
	letter-spacing: -0.28px;
	color: #f6f6f8;
	margin-top: 16px;
}

.cardWrapper:hover .cardContent {
	color: #181818;
}

.cardDetailWrapper {
	position: absolute;
	bottom: 20px;
	left: 12px;
	display: flex;
	align-items: center;
	cursor: pointer;
	@include Pretendard(15px, 500, 1.07);
	letter-spacing: -0.28px;
	color: #f6f6f8;
}

.cardWrapper:hover .cardDetailWrapper {
	filter: invert(1);
}

.cardDetailWrapper img {
	margin-left: 8px;
	transition-duration: 1s;
}

.cardDetailWrapper:hover img {
	margin-left: 12px;
}

.swiperContainer {
	width: 100%;
	margin: 0px auto;
	clip-path: inset( -100vw -100vw -100vw 0 );
	margin-top: 46px;
}

.joinBtn {
	background-color: #fff;
	@include Pretendard(17px, 500, 1);
	text-align: center;
	color: #151516;
	padding: 12px 24px;
	margin-top: 42px;
	width: fit-content; 
	cursor: pointer;
	display: block;
}
