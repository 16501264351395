$main: #ff424d;
$sub: #ff5300;
$black: #000;
$white: #ffffff;
$gray-2: #e2e2ec;
$gray-5: #acacb2;
$gray-6: #63626b;
$gray-7: #43434a;
$gray-8: #151516;

$breakpoint-mobile: 360px;
$breakpoint-desktop: 1024px;

@mixin SpoqaHanSansNeo($font-size, $font-weight, $line-height) {
  font-family: "Spoqa Han Sans Neo", Arial, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin Montserrat($font-size, $font-weight, $line-height) {
  font-family: "Montserrat", Arial, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin Pretendard($font-size, $font-weight, $line-height) {
  font-family: "Pretendard", Arial, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}
