@import "../../../../styles/utils.scss";

.wrapper {
	margin: 0px auto;
    padding: 0px 16px;
    padding-top: 80px;
    padding-bottom: 40px;
}

.subtitleEng {
    text-align: center;
	@include Montserrat(12px, 400, 16px);
    margin-bottom: 4px;
    color: #18191A;
    letter-spacing: -0.225px;
}

.subtitleKr {
	@include Pretendard(24px, 800, 40px);
	color: #151516;
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: -0.525px;
}

.subtitleDiv {
    display: inherit;
}

.listWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 14px;
    padding-right: 24px;
    cursor: pointer;
}

.listTitle {
    display: flex;
    align-items: center;
    @include Pretendard(24px, 600, normal);
    color: #151516;
}

.ltLeft {
    margin-right: 14px;
    letter-spacing: -0.34px;
    color: #151516;
}
.ltRight{
    @include Pretendard(22px, 500, normal);
    letter-spacing: -0.31px;
    color: #43434a;
}

.arrow img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
}

.barContainer {
    display: flex;
}

.btn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.elemWrap {
    width: 787px;
    @include Pretendard(20px, 500, 1.6);
    letter-spacing: -0.38px;
    color: #18191a; 
    max-height: fit-content;
    transition: 1s;  
    padding: 36px 14px;
    overflow: hidden;
}
.elemHidden {
    @include Pretendard(20px, 500, 1.6);
    letter-spacing: -0.38px;
    width: 787px;
    transition: 1s;  
    max-height: 0px;
    padding: 0px 14px;
    overflow: hidden;
}

.album{
    width: 175px;
    height: 175px;
    position: relative;
}

.tag {
    position: absolute;
    top: 10px;
    left: 8px;
    transform: rotate(0deg) !important;
}

.elem {
    border: 1px solid black;
}

.elemTitle {
    padding: 0px 6px;
    @include Pretendard(14px, 600, 1.71);
    letter-spacing: -0.26px;
    color: #202429;
    margin-top: 4px;
}

.elemId {
    padding: 0px 6px;
    @include Pretendard(14px, 600, 1.71);
    letter-spacing: -0.26px;
    color: #202429;
}

.bottomInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 6px;
    margin-top: 13px;
    padding-bottom: 6px;
}

.leftTop {
    @include Pretendard(10px, 400, 1.6);
    letter-spacing: -0.17px;
    color: #202429;
}

.leftBottom {
    @include Pretendard(13px, 500, 1.54);
    letter-spacing: -0.19px;
    color: #202429;
}

.rightBottom {
    @include Pretendard(13px, 500, 1.23);
    letter-spacing: -0.24px;
    color: #181818;
    text-decoration: underline;
    cursor: pointer;
}

.title {
    color: #151516;
    @include Pretendard(20px, 600, 20px);
    letter-spacing: -0.286px;
    margin-bottom: 8px;
}

.content {
    color: #4C4C4C;
    @include Pretendard(14px, 500, 20px);
    letter-spacing: -0.262px;
    margin-bottom: 24px;
    transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
    max-height: 200px;
    overflow: hidden;
}

.contentHidden {
    color: #4C4C4C;
    @include Pretendard(14px, 500, 20px);
    letter-spacing: -0.262px;
    margin-bottom: 24px;
    transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
    max-height: 0px;
    overflow: hidden;
}