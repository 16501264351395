@import "../../styles/utils.scss";


.container {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    min-width: 1080px;
    height: 100px;
    z-index: 34;
    border-top : solid 1px #000;
    border-bottom: solid 1px #000;
}

.wrapper {
    // width: 1080px;
    margin: 0px auto;
    width: calc(100% - 96px);
    padding: 0px 48px;
}

.cover {
    width: 72px;
    height: 72px;
    object-fit: fill;
}

.upSide {
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.upSideLeft {
    display: flex;
    align-items: center;
}

.controllers {
    display: flex;
    align-items: center;
    width: 108px;
    justify-content: space-between;
    margin-left: 16px;
}

.right, .left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.right img, .left img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.playBtn {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.playBtn img {
    width: 27px;
    height: 27px;
    object-fit: contain;
}

.songInfo {
    margin-left: 26px;
}

.artist {
    @include Pretendard(14px, 300, 1.14);
    letter-spacing: -0.23px;
    color: #202429;
    margin-bottom: 8px;
}

.title {
    @include Pretendard(22px, bold, 1.09);
    letter-spacing: -0.41px;
    color: #151516;
}

.upSideRight {
    display: flex;
    align-items: center;
}

.volume {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.volume img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-right: 14px;
    filter: brightness(0) saturate(100%);
    cursor: pointer;
}

.clickVolume {
    width: 120px;
    height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.displayVolume {
    width: 120px;
    height: 4px;
}

.investBtn {
    width: 101px;
    height: 40px;
    background-color: #151516;
    @include Pretendard(14px, 500, 1);
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downSide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
}

.currentTime {
    @include Pretendard(10px, 400, 1.2);
    color: #202429;
    margin-right: 16px;
}

.endTime {
    @include Pretendard(10px, 400, 1.2);
    color: #202429;
    margin-left: 16px;
}

.clickTime {
    height: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.displayTime {
    height: 4px;
    width: 100%;
    background-color: #e6e6e6;
    transition: all linear 1s;
}

.black {
    height: 4px;
    transition: all linear 0.5s;
}

.clickLayer {
    position: absolute;
    width: 100%;
    height: 16px;
    z-index: 35;
}