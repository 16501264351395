@import "../../styles/utils.scss";

.container {
	width: 100%;
	margin-top: 80px;
    height: calc(100vh - 53px);
    @include desktop {
    	border-top: solid 1px #e2e2ec;
    	background-color: #fbfbfb;
    	min-width: 960px;
    }
}

.contents {
	@include desktop {
		width: 633px;
		background-color: white;
		border: solid 1px #e2e2ec;
		margin: 0px auto;
		margin-top: 102px;
		border-radius: 10px;
	}
	@include mobile {
		height: 100%;
	}
}

.main {
	@include desktop {
		width: calc(100% - 80px);
		padding: 40px 40px 0px 40px;
	}
	@include mobile {
		width: calc(100% - 40px);
		padding: 40px 20px;
		height: calc(100% - 80px);
		position: relative;
	}
}


.logo {
	display: block;
	margin: 0 auto;
	width: 132px;
	margin-bottom: 12px;
	@include desktop {
		margin-top: 24px
	}
	@include mobile {
		margin-top: 222px
	}

}

.titleText {
	@include SpoqaHanSansNeo(14px, 600, 2.29);
	text-align: center;
	margin-bottom: 120px;
}

.mainBtns {
	@include mobile {
		width: calc(100% - 42px);
		position: absolute;
		bottom: 40px;
	}
}

.googleBtn {
	@include SpoqaHanSansNeo(16px, 500, 1.5);
	color: #747576;
	margin: 0px auto;
	border-radius: 6px;
	border: solid 1px #e5e6e8;
	cursor: pointer;
	text-align: center;
	position: relative;
	@include desktop {
		width: 529px;
		margin-bottom: 24px;
		height: 24px;
		padding: 20px 12px;
	}
	@include mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 24px);
		margin-bottom: 8px;
		padding: 4px 12px;
	}
}

.googleBtn img {
	width: 56px;
	height: 56px;
	-webkit-user-drag: none;
	-moz-user-drag:none;
	-ms-user-drag:none;
	user-drag:none;
	@include desktop {
		position: absolute;
		top:4px;
		left: 12px;
	}
}

.kakaoBtn {
	@include SpoqaHanSansNeo(16px, 500, 1.5);
	color: #151516;
	background-color: #ffe95a;
	margin: 0px auto;
	border-radius: 6px;
	border: solid 1px #e5e6e8;
	cursor: pointer;
	text-align: center;
	position: relative;
	@include desktop {
		width: 529px;
		margin-bottom: 24px;
		height: 24px;
		padding: 20px 12px;
	}
	@include mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 24px);
		margin-bottom: 8px;
		padding: 4px 12px;
	}
}

.kakaoBtn img {
	width: 56px;
	height: 56px;
	-webkit-user-drag: none;
	-moz-user-drag:none;
	-ms-user-drag:none;
	user-drag:none;
	@include desktop {
		position: absolute;
		top:4px;
		left: 12px;
	}
}

.mainTitle {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #13141a;
	padding-bottom: 16px;
}

.mainBottom {
	height: 88px;
	padding: 16px 20px;
	box-shadow: 0 -1px 8px 0 rgba(24, 25, 26, 0.1);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include mobile {
		width: calc(100% - 40px);
		position: absolute;
		bottom: 0px;
	}
	@include desktop {
		margin-top: 40px;
	}
}

.prevBtn {
	@include SpoqaHanSansNeo(14px, 500, 1.57);
	letter-spacing: -0.2px;
	color: #747576;
	cursor: pointer;
	margin-right: 32px;
}

.completeBtn {
	@include SpoqaHanSansNeo(14px, 500, 1.57);
	padding: 17px 57px;
	border-radius: 6px;
	background-color: #000;
	cursor: pointer;
	letter-spacing: -0.35px;
	color: #fff;
}