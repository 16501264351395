@import "../../../../styles/utils.scss";

.container {
    background-color: #000;
    color: #FFF;
    padding: 44px 16px;
}

.title {
    height: 354px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    @include Pretendard(22px, 700, 33px);
    margin-bottom: 8px;
}

.sub {
    @include Pretendard(16px, 500, 24px);
    color: #F6F6F8;
    letter-spacing: -0.229px;
}

.btn{
    width: fit-content;
    cursor: pointer;
    margin: 0px auto;
    margin-top: 45px;
    display: inline-block;
    color: #151516;
    @include Pretendard(14px, 500, normal);
    text-align: center;
    padding: 11.5px 32px;
    background-color: #FFF;
}