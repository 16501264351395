@import "../../../../styles/utils.scss";

.container {
	margin: 0px auto;
	margin-top: 5px;
	padding: 15px;
	margin-bottom: 48px;
}

.elementContainer {
	width: 100%;
	padding-top: 0px;
	// flex: 0 0 auto;
	height: fit-content;
	flex-direction: column;
    grid-gap: 0;
	position: relative;
	overflow: hidden;
}

.contentImg {
	width: 100%;
	object-fit: contain;
}

.leftSide {
	width: 430px;
	margin-right: 65px;
}

.contentImg {
	width: 100%;
	object-fit: contain;
	transform: translateY(0%);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.contentImgHidden {
	transform: translateY(100%);
	clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.title {
	width: 100%;
	margin-top: 15px;
	@include Pretendard(36px, 700, normal);
	letter-spacing: -0.655px;
	color: #000;
	transform: translateY(0%);
    clip-path: inset(0 0 0 0);
	// transform: translateY(100%);
    // clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.titleHidden {
	width: 100%;
	margin-top: 15px;
	@include Pretendard(36px, 700, normal);
	letter-spacing: -0.655px;
	color: #000;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.subtext {
	width: 100%;
	margin-top: 10px;
	@include Pretendard(16px, 500, 24px);
	letter-spacing: -0.229px;
	transform: translateY(0%);
    clip-path: inset(0 0 0 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.subtextHidden {
	width: 100%;
	margin-top: 10px;
	@include Pretendard(16px, 500, 24px);
	letter-spacing: -0.229px;
	transform: translateY(100%);
    clip-path: inset(0 0 100% 0);
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.btnWrapper {
	display: flex;
	margin-top: 40px;
}

.detailBtn {
	padding: 11px 25px;
	background-color: #151516;
	@include Pretendard(14px, 500, 1);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 36px;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}

.detailBtnHidden {
	padding: 11px 25px;
	background-color: #151516;
	@include Pretendard(14px, 500, 1);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 36px;
	opacity: 0;
	transition: all 1000ms cubic-bezier(0.87, 0, 0.13, 1);
}


.investBtn {
	width: 126px;
	height: 49px;
	border-radius: 4px;
	border: solid 1px #151516;
	background-color: #fff;
	@include Pretendard(17px, 500, 1);
	color: #151516;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.rightSide {
	width: 585px;
	position: relative;
}

.playbtn {
	z-index: 2;
	position: absolute;
	width: 64px;
	top: 148px;
	left: 261px;
	cursor: pointer;
}

.objectFit {
	object-fit: cover;
}

.paginationContainer {
	display: flex;
	margin-top: 30px;
	width: 100%;
	grid-gap: 16px
}

.pagination {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: none;
    border: 1px solid #000;
	@include Pretendard(16px, 700, 1);
    border-radius: 48px;
    color: #000;
    text-align: center;
}

.paginationActive {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: none;
    border: 1px solid #000;
	@include Pretendard(16px, 700, 1);
    border-radius: 48px;
    color: #000;
    text-align: center;
	background-color: #ff424d;
}

.pagination:hover {
	background-color: #ff424b49;
}